import React, {useEffect} from 'react'
import Logo from '../image/logo.png'
import Google from '../svg/google.svg'

function Login() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className='w-full pt-[120px] pb-[240px]' style={{ background: 'linear-gradient(0deg, #DFEBFF 0%, #FBFEFF 100%)' }}>
            <div className=''>
                <img className='w-[149px] h-[48px] mx-auto' src={Logo} alt='logo' />
                <h2 className='mt-12 text-center lg:font-semibold font-medium'>Welcome back!</h2>
                <div className='flex justify-center'>
                    <form className='logForm lg:w-[30%] sm:w-[80%] w-full space-y-6 mt-10 bg-white'>
                        <div className='flex justify-center space-x-2 py-5 sign-field w-full'>
                            <img src={Google} alt='google' />
                            <div className='text-[#006FFF] text-base font-semibold'>Sign in with Google</div>
                        </div>
                        <div className='text-base font-normal leading-[24px] mx-auto'>or</div>
                        <input className='form-field' placeholder='Email' />
                        <input className='form-field' placeholder='Password' />
                        <div className='text-base font-medium'>Forget password?</div>
                        <button className='sub-btn py-[18px]' type='submit'>Log in</button>
                        <div className='text-base font-normal leading-[24px] mx-auto'>Don’t have an account? <span className='font-bold'><a href='#/contact'>Contact Us</a></span></div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
