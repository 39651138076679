import * as yup from 'yup';
import validator from 'validator';

export const signupFormSchema = yup.object().shape({
  first_name: yup.string().required("Please enter your fisrt name"),
  last_name: yup.string().required("Please enter your last name"),
  email: yup.string().required("Please enter the email").test("is-valid-email", "Invalid email", (value) =>
    validator.isEmail(value)
  ),
  phone_no: yup.string().required("Please enter your phone no"),
  company: yup.string().required("Please enter your company name"),
 
   
});