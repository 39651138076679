import React from 'react'
import ImperiumLogo from '../image/ImperiumLogo.png'
import linkedin from '../svg/Linkedin.svg'
import fb from '../svg/Facebook.svg'
import insta from '../svg/Insta.svg'
import twit from '../svg/Twit.svg'
import youtube from '../svg/Youtube.svg'
import { currentYear } from '../contsants/constant'

function ImpFooter() {
    return (
        <div>
            <div className='border border-t-[#EEE] bg-[#FBFBFB] py-12'>
                <div className="top_section flex lg:flex-row flex-col lg:space-y-0 space-y-8 items-center justify-center space-x-0 lg:space-x-20">
                    <a href='https://www.imperiumapp.com/' target="_blank" >
                        <img className='w-[131.171px] h-[40px]' src={ImperiumLogo} />
                    </a>
                    <div className='text-[#4A4A4A] text-base font-normal pt-2'>© {currentYear} Imperium. All rights reserved.</div>
                    <div className='flex items-center space-x-6'>
                        <a href="https://www.linkedin.com/company/imperium-software-technologies/" target="_blank"><img src={linkedin} alt="" /></a>
                        <a href="https://www.facebook.com/imperiumapp" target="_blank"><img src={fb} alt="" /></a>
                        <a href="https://www.instagram.com/imperiumsoftware/" target="_blank"><img src={insta} alt="" /></a>
                        <a href="https://twitter.com/imperiumapp" target="_blank"><img src={twit} alt="" /></a>
                        <a href="https://www.youtube.com/user/Imperiumapp" target="_blank"><img src={youtube} alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImpFooter
