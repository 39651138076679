import React from 'react'

function CareerD() {
  return (
    <div className='careerD lg:mt-[88px] mt-14'>
      <div className='top_section'>
        <h2 className=''>Explore Our Opportunities</h2>
        <p className='pt-4 w-[85%] sm:w-[70%] mx-auto'>Excited to take the next step? Don't forget to explore our current job openings on our careers page. We have a range of exciting positions waiting for talented individuals like you. Discover the opportunities that align with your skills and passions and begin your journey with us. We can't wait to see what you'll bring to our team!</p>
        <div className='mt-8'>
          <a href='#/openposition' className='open'>View Open Positions</a>
        </div>
      </div>
    </div>
  )
}

export default CareerD
