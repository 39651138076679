import React from 'react'
import Logo from '../image/logo.png'
import { useState } from 'react';

function DataLogin({onLogin}) {

  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  const userName = 'admin@inaipi.com'
  const passWrd = 'admin123'


  const handelUserId = (e) => {
    setUserId(e.target.value)
    setError(false)
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
    setError(false)
  }

  const submitLogin = (e) => {
    e.preventDefault();
    if (userId === userName && password === passWrd) {
      localStorage.setItem('inaipiLog', true);
      onLogin(); 
    } else {
      setError(true);
    }
  }

  return (
    <div className="w-full h-screen flex items-center justify-end">
      <div className='login-card m-auto flex flex-col items-center justify-center relative'>
        <img className="w-[132px] h-[42px]" src={Logo} alt="logo" />
        <div className='login-head mt-4'>Login to continue</div>
        <form onSubmit={submitLogin} action="" className='mt-10 flex flex-col space-y-4 w-full'>
          <label>User name</label>
          <div><input type="text" required onChange={handelUserId} placeholder='Enter your username' /></div>
          <label>Password</label>
          <div><input type="password" required onChange={handlePassword} placeholder='Enter the password' /></div>
          <button className='relative top-3'>Login</button>
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2">
            {error && <div className="error-div">Invalid Credentials</div>}
          </div>
        </form>
      </div>
    </div>
  )
}

export default DataLogin