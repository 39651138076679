import React, {useEffect} from 'react'
import TopNav from '../bars/TopNav'
import Footer from '../bars/Footer'
import product1 from '../image/product1.png'
import product2 from '../image/product2.png'
import product3 from '../image/product3.png'
import product4 from '../image/product4.png'
import product5 from '../image/product5.png'
import product6 from '../image/product6.png'
import product7 from '../image/product7.png'
import product8 from '../image/product8.png'
import product9 from '../image/product9.png'
import product10 from '../image/product10.png'


function Product() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <TopNav />
            <div className='mt-[120px] mb-[73px] top_section'>
                <h2 className='text-center lg:font-semibold font-medium'>Inaipi Products​</h2>
                <p className='mt-8 w-full md:w-[50%] mx-auto text-center text-[#797979]'>Streamline all customer interactions with an integrated omnichannel digital platform. Stay accessible to customers through various touchpoints.​</p>
                <div className='mt-16 grid grid-flow-row md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-14'>
                    <div className="pro flex flex-col p-4 md:p-6" style={{borderRadius: '16px', border: '1px solid #F0F0F0'}}>
                        <img src={product1} alt='product1' />
                        <div className='pt-8 productH'>AI Chatbot</div>
                        <div className='pt-6 productP'>An AI-powered conversational interface that can engage customers in natural language conversations and assist them with basic queries.</div>
                    </div>
                    <div className="pro flex flex-col p-4 md:p-6" style={{borderRadius: '16px', border: '1px solid #F0F0F0'}}>
                        <img src={product2} alt='product2' />
                        <div className='pt-8 productH'>Self-Service Automation</div>
                        <div className='pt-6 productP'>Save time by simplifying complex tasks, and reducing manual efforts. Empower your team to focus on high-value activities while our solution handles the rest.</div>
                    </div>
                    <div className="pro flex flex-col p-4 md:p-6" style={{borderRadius: '16px', border: '1px solid #F0F0F0'}}>
                        <img src={product3} alt='product3' />
                        <div className='pt-8 productH'>Smart-X Rule Engine</div>
                        <div className='pt-6 productP'>Allows for the creation and management of business, enabling efficient decision-making and automation of workflows.</div>
                    </div>
                    <div className="pro flex flex-col p-4 md:p-6" style={{borderRadius: '16px', border: '1px solid #F0F0F0'}}>
                        <img src={product4} alt='product1' />
                        <div className='pt-8 productH'>Digital Agent Workspace</div>
                        <div className='pt-6 productP'>A platform that provides customer service agents with a centralized hub to manage customer interactions across multiple channels.</div>
                    </div>
                    <div className="pro flex flex-col p-4 md:p-6" style={{borderRadius: '16px', border: '1px solid #F0F0F0'}}>
                        <img src={product5} alt='product5' />
                        <div className='pt-8 productH'>Ticketing System</div>
                        <div className='pt-6 productP'>Empower and transform your business workflow with a full-ﬂedged ticketing system with mini CRM. Allow agents to easily create a ticket, record important notes while on a call, trigger real-time notiﬁcations or escalate directly to the concerned teams.</div>
                    </div>
                    <div className="pro flex flex-col p-4 md:p-6" style={{borderRadius: '16px', border: '1px solid #F0F0F0'}}>
                        <img src={product6} alt='product6' />
                        <div className='pt-8 productH'>Collaboration Tools</div>
                        <div className='pt-6 productP'>Facilitate easy collaboration with our featured tools: co-browsing, video chat, file sharing, eKYC with the digital signature feature, field masking, etc.</div>
                    </div>
                    <div className="pro flex flex-col p-4 md:p-6" style={{borderRadius: '16px', border: '1px solid #F0F0F0'}}>
                        <img src={product7} alt='product6' />
                        <div className='pt-8 productH'>CRM Integration</div>
                        <div className='pt-6 productP'>Business applications like CRM/ERP, ticketing tools, etc. can be seamlessly integrated and boosts their ability.</div>
                    </div>
                    <div className="pro flex flex-col p-4 md:p-6" style={{borderRadius: '16px', border: '1px solid #F0F0F0'}}>
                        <img src={product8} alt='product6' />
                        <div className='pt-8 productH'>Smart Survey</div>
                        <div className='pt-6 productP'>An easy-to-use tool for collecting feedback and opinions from customers, providing valuable insights for improving products and services.</div>
                    </div>
                    <div className="pro flex flex-col p-4 md:p-6" style={{borderRadius: '16px', border: '1px solid #F0F0F0'}}>
                        <img src={product9} alt='product6' />
                        <div className='pt-8 productH'>Data Analytics & Reporting</div>
                        <div className='pt-6 productP'>A powerful data analytics tool that can generate actionable insights and help improve business performance.</div>
                    </div>
                    <div className="pro flex flex-col p-4 md:p-6" style={{borderRadius: '16px', border: '1px solid #F0F0F0'}}>
                        <img src={product10} alt='product10' />
                        <div className='pt-8 productH'>Social Media Connectors & Analytics</div>
                        <div className='pt-6 productP'>Integration with social media platforms to manage and monitor social media interactions with customers.</div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Product
