import React, { useState, useEffect } from 'react'
import AvayaHeader from '../bars/AvayaHeader'
import BgCX from '../image/bg-CX.png'
import AvayaImgCX from '../image/AvayaImg.png'
import DevConnectBg from '../image/DevconnectBg.png'
import DevConnect from '../image/DevConnect.png'
import ResDevConnect from '../image/resDevBg.png'
import AvayaExp from '../image/AvayaExp.png'
import Hightlight from '../image/Highlight.png'
import ResHightlight from '../image/ResHighlight.png'
import work from "../image/Work.png";
import cust from "../image/Customer.png";
import light from "../image/Lightbulb.png";
import pattern from "../image/Pattern.png";
import help from "../image/Help desk.png";
import AiChat from "../image/AIchat.png";
import UcxDigital from "../image/UcxDigital.png";
 import TickSys from "../image/TickSys.png";
import Collab from "../image/collab.png";
import Survey from "../image/Survey.png";
import QualityMonitoring  from "../image/qualityMonitoring.png"
import PowerReports from "../image/powerReports.png"
import CustomerSer from "../image/CustomerSer.png";
import Contact from "../image/contact.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import AvayaFooter from '../bars/AvayaFooter'

function AvayaCX() {
    const [countryCode, setCountryCode] = useState("91");
    const [number, setNumber] = useState();
    const [phone, setPhone] = useState();
    const API_URL =
        "https://1vdi4lgmfa.execute-api.us-east-1.amazonaws.com/insert_details";
    const codeHandle = (e) => {
        setCountryCode(e);
    };

    const numberHandle = (e) => {
        console.log("+" + countryCode + " " + e.target.value);
        setNumber(e.target.value);
        setPhone("+" + countryCode + " " + e.target.value);
    };

    const [activeSection, setActiveSection] = useState(1);

    const handleMenuClick = (sectionNumber) => {
        const element = document.getElementById(`section${sectionNumber}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(sectionNumber);
        }
    };


    return (
        <div>
            <AvayaHeader handleMenuClick={handleMenuClick} activeSection={activeSection} />
            <div className='mb-20 lg:mt-[64px] mt-14'>
                <div id="section1" className='bg-cover' style={{ backgroundImage: `url(${BgCX})` }}>
                    <div className='top_section flex lg:flex-row flex-col items-center lg:space-x-5 space-x-0'>
                        <div className="flex-col lg:w-1/2 text-center lg:text-left mt-10 lg:mt-0">
                            <div className='text-[#000] text-[32px] lg:text-[48px] xl:text-[56px] font-semibold'>Redefining Customer<br /> Experience Beyond<br /> Expectations</div>
                            <div className='text-[#4A4A4A] text-[14px] lg:text-[18px] mt-6'>Create an effortless Cloud Contact Center Experience at every touchpoints</div>
                            <button onClick={() => handleMenuClick(6)} className='mt-6 rounded bg-[#D52017] text-[#FFF] font-bold text-sm lg:text-base lg:py-3 lg:px-6 px-[14px] py-3'>Contact us</button>
                        </div>
                        <img className='avayImg my-12 h-[303px] lg:h-[560px] lg:w-[566px] w-[362px]' src={AvayaImgCX} alt='cx' />
                    </div>
                </div>

                <div className='sm:block hidden my-10 py-12 bg-center bg-cover' style={{ backgroundImage: `url(${DevConnectBg})` }}>
                    <div className='top_section'>
                        <div className='w-[80%] lg:w-[60%] lg:py-[64px] lg:px-[48px] py-12 px-7' style={{ background: 'rgba(255, 255, 255, 0.80)', backdropFilter: 'blur(42px)' }}>
                            <img className='w-[297.888px] h-[56px]' src={DevConnect} alt='devconnect' />
                            <div className='py-6 text-[#222] text-base lg:text-xl font-bold'>Avaya is the go-to choice for organizations seeking innovative solutions to enhance customer and employee engagement. Avaya's contact center and communication solutions drive immersive, personalized experiences that boost business growth and allow customers to create their unique journeys.</div>
                            <div className='text-base lg:text-lg text-[#4D4D4D] leading-[28px] font-normal'>As a DevConnect Technology Partner of Avaya, we are recognized for our expertise in a broad spectrum of Avaya products, consistently exceeding customer expectations by delivering exceptional experiences. Our comprehensive range of services and solutions is designed to maximize the value of your investments in Avaya.</div>
                        </div>
                    </div>
                </div>
                <div className='sm:hidden block my-10'>
                    <img src={ResDevConnect} alt='devRes' className='w-full h-[462px] shrink-0'></img>
                    <div className='top_section mt-10'>
                        <img className='w-[266.5px] h-[50.099px]' src={DevConnect} alt='devconnect' />
                        <div className='py-6 text-[#222] text-base font-bold'>Avaya is the go-to choice for organizations seeking innovative solutions to enhance customer and employee engagement. Avaya's contact center and communication solutions drive immersive, personalized experiences that boost business growth and allow customers to create their unique journeys.</div>
                        <div className='text-base text-[#4D4D4D] leading-[28px] font-normal'>As a DevConnect Technology Partner of Avaya, we are recognized for our expertise in a broad spectrum of Avaya products, consistently exceeding customer expectations by delivering exceptional experiences. Our comprehensive range of services and solutions is designed to maximize the value of your investments in Avaya.</div>
                    </div>
                </div>
                <div id="section2" className='top_section bg-[#FBFBFB] lg:py-20 py-10 lg:px-[48px] px-0 mt-10 flex flex-col items-center justify-center text-center'>
                    <img src={AvayaExp} alt='AvayaExperience' className='lg:w-[560.963px] lg:h-[48px] w-[332.998px] h-[28.494px]' />
                    <div className='mt-6 text-base lg:text-[24px] text-[#212327] font-bold lg:leading-[40px] leading-[28px]'>A hassle-free, always-on contact center that provides a fully integrated and open Contact Center as a Service (CCaaS) architecture with scalability, security, and in-depth analytics across the customer journey for a simple and flexible cloud experience.</div>
                    <div className='text-[#4D4D4D] text-base lg:text-[22px] mt-6 font-normal leading-[28px] lg:leading-[40px]'>Avaya Experience Platform unifies voice, video, chat, and messaging to provide smooth interactions for both customers and employees across all touchpoints. Connect customer and employee touchpoints with 360-degree visibility, convenient co-browsing, easy CRM integrations, and more.</div>
                </div>

                <div id="section3" className='my-20 top_section'>
                    <div className="pro bg-[#FFF3F2] lg:pl-[64px] pr-0 flex lg:flex-row flex-col items-center justify-between w-full">
                        <div className="flex-col lg:text-left text-center lg:py-12 py-5 px-2 lg:px-0">
                            <div className='text-[28px] lg:text-[48px] text-[#D72A1D] font-semibold pt-5 lg:pt-0'>Highlights</div>
                            <div className='mt-8 lg:mr-14 mr-0 flex-col lg:space-y-5 space-y-3 text-[#4D4D4D] font-normal lg:text-[20px] text-base lg:leading-[40px] tracking-[-0.32px] lg:tracking-[-0.48px]'>
                                <div>Supports AXP, AACC, ACCS, and ELITE</div>
                                <div>Maximize the possibilities of the platforms you already have </div>
                                <div>Modernize and digitalize customer experience </div>
                                <div>Embrace market trends toward cloud contact centre solutions </div>
                                <div>Customizable to unique requirements, no matter the industry</div>
                            </div>
                        </div>
                        <div className="lg:flex hidden justify-end">
                            <img src={Hightlight} alt='highlight' className='h-[404px] w-[404px]' />
                        </div>
                        <div className='lg:hidden flex items-end mt-5'>
                            <img className='h-[308px]' alt='highlight' src={ResHightlight}></img>
                        </div>
                    </div>
                </div>

                <div id="section4" className='my-20 top_section'>
                    <div className='text-center text-[#D72A1D] font-semibold text-[28px] lg:text-[48px]'>Why Inaipi? </div>
                    <div className='py-8 lg:py-10 leading-[40px] text-center lg:text-[24px] text-base font-bold' style={{color : "var(--sub, #5A5A5A)"}}>
                    Grappling with on-premise challenges? Looking for a seamless transition to the cloud while keeping your workflow intact? We ensure you a smooth and hassle-free migration to the Cloud.
                    </div>
                    <div className='CxCount mt-10'>
                        <div className="grid grid-flow-row lg:grid-cols-3 lg:my-10 my-0  grid-cols-1 gap-20 place-content-center place-items-center">
                            <div className="relative flex justify-center items-center mt-8 lg:mt-0">

                                <div className="absolute flex flex-col space-y-3">
                                    <div className="CXNo">1000+</div>
                                    <div className="CXRt">Customers Served</div>
                                </div>
                                <div className="CxLine relative lg:left-40 lg:bottom-0 right-0 -bottom-16 lg:rotate-0 rotate-90"></div>
                            </div>
                            <div className="relative flex justify-center items-center">
                                <div className="absolute flex flex-col space-y-3">
                                    <div className="CXNo">2000+</div>
                                    <div className="CXRt">Active Users</div>
                                </div>
                                <div className="CxLine relative w-1 lg:left-44 lg:bottom-0 right-0 -bottom-14 lg:rotate-0 rotate-90 shrink-0"></div>
                            </div>
                            <div className="relative flex justify-center items-center mt-12 mb-24 lg:mb-0 lg:mt-0">
                                <div className="absolute flex flex-col space-y-3">
                                    <div className="CXNo">750+</div>
                                    <div className="CXRt">Deployed CC</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="top_section lg:mt-[64px] mt-[24px]">
                    <div className="w-full lg:mt-20 mt-8 grid grid-flow-row lg:grid-cols-3 grid-cols-1 gap-6">
                        <div className="card cxCard pro flex flex-col text-left shrink-0">
                            <div className='relative lg:w-[64px] lg:h-[64px] w-[52px] h-[52px] flex items-center justify-center shrink-0'>
                                <img src={work} alt='work' className="lg:w-8 lg:h-8 w-[26.667px] h-[26.667px]" />
                            </div>
                            <div className="cardH">Expertise</div>
                            <div className="cardP">
                                Our team consists of industry experts who bring years of
                                experience to provide the best-in-class solutions that align
                                with your business goals.
                            </div>
                        </div>
                        <div className=" card cxCard pro flex flex-col shrink-0">
                            <div className='relative lg:w-[64px] lg:h-[64px] w-[52px] h-[52px] flex items-center justify-center shrink-0'>
                                <img src={cust} alt='customer' className="lg:w-8 lg:h-8 w-[26.667px] h-[26.667px]" />
                            </div>
                            <div className="cardH">Customer-Centric Approach</div>
                            <div className="cardP">
                                Your success is our priority. We take the time to understand
                                your specific requirements, ensuring that our solutions meet and
                                exceed your expectations.
                            </div>
                        </div>

                        <div className="cxCard pro flex flex-col shrink-0">
                            <div className='relative lg:w-[64px] lg:h-[64px] w-[52px] h-[52px] flex items-center justify-center shrink-0'>
                                <img src={light} alt='light' className="lg:w-8 lg:h-8 w-[26.667px] h-[26.667px]" />
                            </div>
                            <div className="cardH">Innovation</div>
                            <div className="cardP">
                                We stay ahead of the curve by embracing the latest technologies
                                and trends. Our innovative products and services keep you at the
                                forefront of your industry.
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 lg:mx-40 grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-6">
                        <div className="cxCard pro flex flex-col shrink-0">
                            <div className='relative lg:w-[64px] lg:h-[64px] w-[52px] h-[52px] flex items-center justify-center shrink-0'>
                                <img src={pattern} alt='pattern' className="lg:w-8 lg:h-8 w-[26.667px] h-[26.667px]" />
                            </div>
                            <div className="cardH">Seamless Integration</div>
                            <div className="cardP">
                                Implementing new systems can be daunting, but with Inaipi, the
                                process is smooth and hassle-free. Our team ensures seamless
                                integration and minimal disruptions to your operations.
                            </div>
                        </div>
                        <div className="cxCard pro flex flex-col shrink-0">
                            <div className='relative lg:w-[64px] lg:h-[64px] w-[52px] h-[52px] flex items-center justify-center shrink-0'>
                                <img src={help} alt='help' className="lg:w-8 lg:h-8 w-[26.667px] h-[26.667px]" />
                            </div>
                            <div className="cardH">Ongoing Support</div>
                            <div className="cardP">
                                Our commitment to your success extends beyond implementation. We offer continuous support and updates to ensure your business stays agile and competitive.
                            </div>
                        </div>
                    </div>
                </div>

                <div id="section5" className="top_section lg:mt-[64px] mt-[24px]">
                    <div className='text-center text-[#D72A1D] font-semibold text-[28px] lg:text-[48px]'>What We Offer</div>
                    <div className='mt-8 text-center text-base lg:text-[24px] font-bold' style={{ color: "var(--sub, #5A5A5A)" }}>
                        Bridging the Gap: Where On-Premises Meets the Cloud!
                    </div>
                    <div className='mt-4 text-center text-sm lg:text-[20px] font-medium' style={{ color: "var(--sub, #5A5A5A)" }}>
                        We provide you with cloud-based solutions to what you already have
                    </div>
                    <div className='mt-6 offerCards flex lg:flex-row flex-col lg:space-x-5 space-x-0'>
                        <img className='lg:w-[480px] lg:h-[400px] w-full sm:w-[347px] h-[248px]' src={AiChat} alt='chatbot' />
                        <div className="flex-col">
                            <div className='offerH'>AI Chatbot</div>
                            <div className='offerP'>An AI-powered conversational interface that can engage customers with multi-lingual capabilities and assist them with basic queries.</div>
                        </div>
                    </div>
                    <div className='mt-6 offerCards  flex lg:flex-row flex-col-reverse lg:space-x-5 space-x-0'>
                        <div className="flex-col">
                            <div className='offerH'>Smart-X Rule Engine</div>
                            <div className='offerP'>
                            A No-Code system that helps Enterprises to host all the business rules in a centralized place and extend to any of the customer touchpoints seamlessly. Provides integration to enterprise applications like mobile app, web, Call center, back-office and any other self-service channels through API’s.
                            </div>
                        </div>
                     </div>
                    
                    <div className='mt-6 offerCards  flex lg:flex-row-reverse flex-col-reverse'>
                        <div className="flex-col lg:w-[46%]">
                            <div className='offerH'>Digital UCX</div>
                            <div className='offerP'>Revamp the entire customer journey using AI chatbots, digital assistants, self-service automation, social media platforms, intelligent routing, social monitoring, and analytics to create an exceptional and distinctive experience from beginning to end.</div>
                        </div>
                        <img className='lg:w-[480px] lg:h-[400px] w-full sm:w-[347px] h-[248px]' src={UcxDigital} alt='chatbot' />
                    </div>
                    <div className='mt-6 offerCards flex lg:flex-row-reverse flex-col '>
                        <img className='lg:w-[480px] lg:h-[400px] w-full sm:w-[347px] h-[248px]' src={TickSys} alt='chatbot' />
                        <div className="flex-col  lg:w-[46%]">
                            <div className='offerH'>Ticketing System</div>
                            <div className='offerP'>Streamline your support operations, empower agents, and enhance customer service. Enable agents to easily create tickets and capture essential information, trigger immediate real-time notifications or directly escalate issues to the appropriate teams.</div>
                        </div>
                    </div>
                    <div className='mt-6 offerCards  flex lg:flex-row-reverse flex-col-reverse'>
                        <div className="flex-col lg:w-[46%]">
                            <div className='offerH'>Collaboration Tools</div>
                            <div className='offerP'>Facilitate easy collaboration with our featured tools: co-browsing, video chat, file sharing, eKYC with the digital signature feature, field masking, etc.</div>
                        </div>
                        <img className='lg:w-[480px] lg:h-[400px] w-full sm:w-[347px] h-[248px]' src={Collab} alt='chatbot' />
                    </div>
                    <div className='mt-6 offerCards flex lg:flex-row-reverse flex-col lg:space-x-5 space-x-0'>
                        <img className='lg:w-[480px] lg:h-[400px] w-full sm:w-[347px] h-[248px]' src={Survey} alt='chatbot' />
                        <div className="flex-col  lg:w-[46%]">
                            <div className='offerH'>Smart Survey</div>
                            <div className='offerP'>Analyze customer service quality and customer satisfaction in real-time by conducting automated post-interaction surveys seamlessly on IVR, SMS, email, and social media. Stay attuned to your customers' needs, make data-driven decisions, and maintain a competitive edge in today's market.</div>
                        </div>
                    </div>
                    <div className='mt-6 offerCards  flex lg:flex-row-reverse flex-col-reverse'>
                        <div className="flex-col lg:w-[46%]">
                            <div className='offerH'>Quality monitoring</div>
                            <div className='offerP'>Empower your calls with precision and reliability. Evaluate and enhance call center interactions seamlessly, monitoring conversations and pinpointing areas for improvement.</div>
                        </div>
                        <img className='lg:w-[480px] lg:h-[400px] w-full sm:w-[347px] h-[248px]' src={QualityMonitoring} alt='QualityMonitoring' />
                    </div>
                    <div className='mt-6 offerCards flex lg:flex-row-reverse flex-col lg:space-x-5 space-x-0'>
                        <img className='lg:w-[480px] lg:h-[400px] w-full sm:w-[347px] h-[248px]' src={PowerReports} alt='PowerReports' />
                        <div className="flex-col  lg:w-[46%]">
                            <div className='offerH'>Power Reports</div>
                            <div className='offerP'>Integration with Microsoft Power Bi Tool with real-time live dashboard, historical data analysis, and generation of customized reports</div>
                        </div>
                    </div>
                </div>

                <div id="section6" className="top_section mt-[120px] mb-[73px]">
                    <div className="flex lg:flex-row lg:space-x-14 flex-col bg-[#FBFBFB] lg:px-12 lg:py-14 md:p-6 p-0 rounded-2xl">
                        <div className="flex-col lg:text-left text-center">
                            <h2 className="text-[#D72A1D] font-semibold text-[28px] lg:text-[48px]">Reach Us</h2>
                            <p className="w-full sm:w-[60%] lg:w-full lg:flex mx-auto lg:mx-0 mt-6 text-[#4D4D4D] lg:text-left text-center">
                                We are here to listen, assist, and provide the support you need.
                                We look forward to helping you and ensuring your experience with
                                us exceeds your expectations.
                            </p>
                            <div className="mx-auto lg:mx-0 mt-[56px] lg:w-[341.927px] lg:h-[258.778px] w-[290.795px] h-[220.078px] shrink-0">
                                <img
                                    src={Contact}
                                    alt="contact_us"
                                />
                            </div>
                        </div>
                        <form
                            className="form space-y-2 lg:mt-0 mt-10"
                        >
                            <div className="flex sm:flex-row flex-col sm:space-x-5 space-y-4 sm:space-y-0">
                                <div className="flex flex-col space-y-2">
                                    <label className="label">First name</label>
                                    <input
                                        required
                                        placeholder="Your first name"
                                        className="form-field"
                                    />
                                </div>
                                <div className="flex flex-col space-y-2">
                                    <label className="label">Last name</label>
                                    <input
                                        required
                                        placeholder="Your last name"
                                        className="form-field"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <label className="label">Company</label>
                                <input
                                    placeholder="company name"
                                    className="form-field"
                                />
                            </div>
                            <div className="flex flex-col space-y-2">
                                <label className="label">Business email</label>
                                <input
                                    required
                                    placeholder="example@gmail.com"
                                    className="form-field"
                                />
                            </div>
                            <div className="flex flex-col space-y-2">
                                <label className="label">Phone number</label>
                                <div className="flex space-x-6">
                                    <PhoneInput
                                        inputProps={
                                            {
                                                // disabled: true,
                                            }
                                        }
                                        className="form-field"
                                        style={{ width: "30%" }}
                                        country={"in"}
                                        value={countryCode}
                                        onChange={codeHandle}
                                    />
                                    <input
                                        required
                                        type="text"
                                        className="form-field w-full"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <label className="label">Anything else? (Optional)</label>
                                <textarea
                                    placeholder="What changes are looking for?"
                                    className="form-field h-[72px] placeholder:pt-2"
                                />
                            </div>
                            <button
                                className="cxBtn py-[18px] relative"
                                type="submit"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>

            </div>
            <AvayaFooter />
        </div>
    )
}

export default AvayaCX
