import React, { useEffect } from "react";

const GoogleAnalytics = () => {
  useEffect(() => {
    // Initialize the Google Ads tracking code
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "AW-10880559221");

    // Create the script element for the tracking code
    const scriptElement = document.createElement("script");
    scriptElement.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag() { window.dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'AW-10880559221');`;

    // Append the script element to the document's head
    document.head.appendChild(scriptElement);
    return () => {
      // Remove the script element when the component unmounts
      document.head.removeChild(scriptElement);
    };
  }, []);

  return null; // This component doesn't render anything
};

export default GoogleAnalytics;
