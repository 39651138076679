import React, { useEffect } from "react";

const SuccessAnalytics = () => {
  useEffect(() => {
    // Create the script element for the conversion tracking code
    const conversionScript = document.createElement("script");
    conversionScript.innerHTML = `
          gtag('event', 'conversion', {
            'send_to': 'AW-10880559221',
            'value': 0.5,
            'currency': 'AED'
          });
        `;

    // Append the conversion script element to the document's head
    document.head.appendChild(conversionScript);

    return () => {
      // Remove the conversion script element when the component unmounts
      document.head.removeChild(conversionScript);
    };
  }, []);

  return null;
}

export default SuccessAnalytics;
