import { Dialog, Transition } from "@headlessui/react";
import { Button, TextField, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { convertDateString, validateEmail } from "../../../utils/util";
import { sent_contact_us } from "../../../actions/contact";
 
export default function DocForm(props) {
  const { hide, link, docName, doc_link } = props;
  const [formInfo, setFormInfo] = useState({
    name: localStorage.getItem('doc_user_name') ? localStorage.getItem('doc_user_name') :"",
    email: localStorage.getItem('doc_email') ? localStorage.getItem('doc_email') :"",
  });

  const [validationObj, setValidationObj] = useState({
    name: "",
    email: "",
  });

  const [errorFlag, setErrorFlag] =  useState(false)
  const [loading, setLoading] =  useState(false)

  const handleInputs = (input, type) => {
    switch (type) {

      case "name":
        setFormInfo((prev) => ({ ...prev, name: input }));
        setValidationObj((prev) => ({...prev, name: "" }));
        break;

      case "email":
        setFormInfo((prev) => ({ ...prev, email: input }));
        setValidationObj((prev) => ({...prev, email: "" }));
        break;

      default:
        break;
    }
  };

  function closeModal() {
    hide();
  }

  const handleSubmit = (e) => {
    if (!formInfo?.name) {
      setValidationObj((prev) => ({...prev, name: "name is required" }));
    } else if (!validateEmail(formInfo?.email)) {
      setValidationObj((prev) => ({...prev, email: "Invalid Email" }));
    } else {
      console.log("successfully saved");
      downLoadNow()
    }
  };

    const downLoadNow = () => {
      var name = docName.replace(/\s+/g, "");
      var dateValue = convertDateString(new Date());
      var body = {
        firstname: formInfo?.name,
        lastname: "doc_type",
        company: docName,
        phone: docName,
        email: formInfo?.email,
        comments: name+"_"+dateValue,
      };
    //   console.log(body)
    setLoading(true)
      sent_contact_us(body)
        .then((res) => {
            console.log(res)
           setLoading(false)
          if(res?.success){
            hide();
            localStorage.setItem(name+"doc", name+"_"+dateValue);
            localStorage.setItem("doc_user_name", formInfo?.name);
            localStorage.setItem("doc_email", formInfo?.email);
            window.open(doc_link, '_blank');
          }else{
            setErrorFlag(true)
          }
        })
        .catch((error) => {
          setErrorFlag(true)
          setLoading(false)
        });
    };

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[99999999] top-0 right-0"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {/* <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    
                  </Dialog.Title> */}
                  <div className="mt-2 flex flex-col gap-8">
                    <TextField
                      onChange={(data) =>
                        handleInputs(data?.target?.value, "name")
                      }                    
                      value={formInfo?.name}
                      style={{ width: "100%" }}
                      id="outlined-multiline-flexible"
                      label="name"
                      multiline
                      maxRows={4}
                      size="small"
                      error={validationObj.name !== ""}
                      helperText={validationObj.name}
                    />
                    <TextField
                      onChange={(data) =>
                        handleInputs(data?.target?.value, "email")
                      }
                      value={formInfo?.email}
                      style={{ width: "100%" }}
                      id="outlined-multiline-flexible"
                      label="Email"
                      multiline
                      maxRows={4}
                      size="small"
                      error={validationObj.email !== ""}
                      helperText={validationObj.email}
                    />
                  </div>

                  <div className="mt-4 flex justify-center">
                    {/* <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button> */}
                    <Button  loading onClick={() => handleSubmit()} variant="contained">
                      Download now
                      {loading && <div className="custom-loader-contact ml-2"></div>}
                    </Button>
                  </div>
                   {errorFlag && <div className="text-red-500 text-center mt-3">Sorry Some thing went wrong</div> }
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
