import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function CustomTab(props) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            TabIndicatorProps={{
              hidden: true,
            }}
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            {props.tabOptions.map((item, i) => (
              <Tab
                style={{ textTransform: "capitalize" }}
                key={i}
                label={item.label}
                value={item.value}
              />
            ))}
          </TabList>
        </Box>
        {props.tabOptions.map((item, i) => (
          <TabPanel key={i} style={{ padding: "0", height:"20px" }} value={item.value}>
            {item.container}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
