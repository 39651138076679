import React from 'react'
import CareerImg1 from '../image/ImgCareer1.png'
import { Link } from 'react-router-dom'

function CareerA() {
  return (
    <div className='top_section head w-full'> 
      <div className='head1 mr-0 lg:mr-10'>
        <h1 className=''>Join Our Team And <br/><span className='text-[#006FFF]'>Shape The Future Of CX</span> </h1>
        <p className='pt-4 text-[#6A6A6A]'>Join us in shaping the future of CX. We're seeking talented individuals who are passionate about creating exceptional experiences.Join our team and make a lasting impact in redefining Customer Experience.</p>
        <Link to='/openposition'><button className='open mt-4'>View Open Positions</button></Link>
      </div>
      <img className='careerImg mt-4 lg:mt-0 fea ml-4 ' src={CareerImg1} alt='careerImage' />
    </div>
  )
}

export default CareerA
