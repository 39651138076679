import React, { useState, useEffect } from "react";
import TopNav from "../../bars/TopNav";
import Footer from "../../bars/Footer";
import plus from "../../svg/Plus.svg";
import minus from "../../svg/minus.svg";
import PressHead from "./PressHead";

function PressRelease() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [divStates, setDivStates] = useState({});

  const toggleDiv = (divId) => {
    setDivStates((prevState) => ({
      ...prevState,
      [divId]: !prevState[divId],
    }));
  };

  return (
    <div>
      <TopNav />
      <div className="press-release-container">
      <PressHead/>
        <div className="top_section min-h-[60vh]">
        <div className="mt-6 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 bg-[#f9f7f7] lg:px-4 px-2 lg:py-4 py-3 rounded-lg">
          <a href="https://www.tahawultech.com/insight/facilitating-a-seamless-digital-transformation-journey-for-you/" target="_blank" className=" h-fit flex items-center gap-4">
            <img src="https://www.tahawultech.com/wp-content/uploads/2024/03/IMPERIUM-WEB.jpg" alt="tahawultech"/>
            <div className="link">
            Facilitating a seamless digital transformation journey for you | TahawulTech.com
            </div>
          </a>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PressRelease;
