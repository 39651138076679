import React from "react";
import Footer from "../../../bars/Footer";
import TopNav from "../../../bars/TopNav";
import "../css/content.css";
import "../css/responsive.css";
import BlogHead from "../components/BlogHead";
import CloudPic from "../images/cloudCover.png";
import PagePic from "../images/cloud.png";
import { cloud_migration_feature, cloud_migration_smooth_transition, social_media_links } from "../../../contsants/constant";
import { Link } from "react-router-dom";
// import { SocialIcon } from 'react-social-icons'

function CloudMigration() {
  return (
    <div>
      <TopNav />
      <BlogHead
        coverPic={CloudPic}
        heading={
          <span>
            Our On-Premise, Supercharged in the Cloud:
            <br /> An Effortless Transition Journey
          </span>
        }
      />
      <div className="blog-content top_section">
        <div className="mt-6 pb-10">
          <div className="sub-head">
            Are you an on-premise Contact Center user looking to upgrade to the
            cloud? Are you seeking a seamless transition to the cloud while
            keeping your existing framework? Look no further – we're here to
            ensure your smooth, hassle-free transition to a cloud platform
            leveraging your current infrastructure.
          </div>
          <div className="mt-8 flex lg:flex-row flex-col items-center gap-6">
            <img className="md:h-[160px] md:w-auto w-full rounded-md" src={PagePic} alt="" />
            <p className="para w-auto  text-justify">
              In today's digital landscape, migrating to the cloud has become a
              necessity for businesses seeking scalability, flexibility, and
              enhanced operational efficiency. However, migrating to the cloud
              can seem daunting, with potential complexities and risks. We
              understand these challenges and are here to provide you with a
              seamless cloud migration experience. Our expertise, coupled with
              advanced solutions, ensures a smooth transition that unlocks the
              full potential of the cloud on top of your existing on-premise
              assets. In this blog, we will explore the cloud benefits and how
              we ease your cloud migration, empowering your business for future
              success.
            </p>
          </div>
          <div className="mt-8">
            <hr />
            <div className="py-5 qoutes">
              By 2026, approximately 70% of organizations will have transitioned
              some or all of their contact center technology to the cloud in
              pursuit of increased flexibility and scalability.
            </div>
            <hr />
          </div>
          <div className="mt-8">
            <div className="sub-head" style={{ color: "#000" }}>
              Bridging the Gap: Where On-Premises Meets the Cloud!
            </div>
            <p className="para mt-3">
              We facilitate a seamless migration to the cloud, delivering
              cloud-based solutions that build upon their existing
              infrastructure. We safeguard their on-premises investments and
              seamlessly integrate them with cloud-based services within a
              hybrid model. Through Hybrid Cloud, organizations can embrace
              innovation by layering cloud services on top of their current
              infrastructure, aligning with their business requirements and
              strategies.
            </p>
          </div>
          <div className="mt-8">
            <div className="sub-head" style={{ color: "#000" }}>
              Here are some advantages of expanding robust cloud capabilities to
              on-premises environments:
            </div>
            <div className="flex xl:flex-row flex-col justify-between mt-4"> 
              <div>
                {cloud_migration_feature.slice(0, 7).map((item, i) => {
                  return <li style={{ listStyleType: 'disc', color: '#006fff' }}><span className="text-black">{item}</span></li>;
                })}
              </div>

              <div>
                {cloud_migration_feature.slice(8, 15).map((item, i) => {
                  return <li style={{ listStyleType: 'disc', color: '#006fff' }}><span className="text-black">{item}</span></li>;
                })}
              </div>
            </div>
            <div className="mt-8">
                <div className="sub-head" >How We Ensure a Smooth Transition for You</div>
               {cloud_migration_smooth_transition.map((item,i)=>{
                return <li className="para" style={{lineHeight:"30px"}} key={i}><span className="text-blue-600 font-semibold"> {item?.head}</span> {item?.para}</li>
               }) }
            </div>
            <div className="mt-8 bg-blue-50 w-full rounded-lg p-10 sub-head">
            We recognize that successful cloud migration is more than just technology implementation; it is a strategic business decision. With us, you gain a trusted ally who simplifies the transition process, mitigates risks, and unlocks the true potential of cloud solutions. Let us be your guide as you embark on this transformative journey, empowering your business for enhanced agility, scalability, and competitiveness in the digital era. 
            </div>
            <div className="mt-8 sub-head text-center" style={{color:"#006fff"}}>
            Contact us today to take your first step towards a seamless cloud migration experience.
            </div>
            <div className="flex w-full flex-col items-center justify-center">
              <Link to="../contact" className="contact mx-auto mt-5 inline-block md:w-auto w-full">Contact Us</Link>
              {/* <div className='flex items-center space-x-6 mt-4'>
                {social_media_links.map((item,i)=>{return <SocialIcon url={item?.link} style={{ height: 30, width: 30 }}/>})}
              </div> */}
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CloudMigration;
