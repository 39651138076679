export const aiDocLink               = "https://inaipiapp.com/files/features/Chatbot.pdf";
export const callAnalyticsDocLink    = "https://inaipiapp.com/files/features/CallAnalytics.pdf";
export const callBackDocLink         = "https://inaipiapp.com/files/features/CallbackManager.pdf";
export const coBrowserDocLink        = "https://inaipiapp.com/files/features/Co-browsing.pdf";
export const crmDocLink              = "https://inaipiapp.com/files/features/CRMCONNECTOR.pdf";
export const dccDocLink              = "https://inaipiapp.com/files/features/InaipiDCC.pdf";
export const qmsDocLink              = "https://inaipiapp.com/files/features/QMS.pdf";
export const outBoundDocLink         = "https://inaipiapp.com/files/features/CBM20.pdf";
export const smartRuleEngineDocLink  = "https://inaipiapp.com/files/features/Smart-X.pdf";
export const smartSurveyDocLink      = "https://inaipiapp.com/files/features/surveyModule.pdf";
export const smsDocLink              = "https://inaipiapp.com/files/features/SMSSolution.pdf";
export const socialEngagementDocLink = "https://inaipiapp.com/files/features/SocialMediaEngagementPlatform.pdf";
export const ticketingDocLink        = "https://inaipiapp.com/files/features/ticketingSystem.pdf";
export const videoKycDocLink         = "https://inaipiapp.com/files/features/VideoKyc.pdf";
export const socialMediaConnectorDocLink    = "https://inaipiapp.com/files/features/SocialMediaConnector.pdf"
