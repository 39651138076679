import React, {useEffect} from 'react'
import Topbar from '../bars/TopNav.js'
import Footer from '../bars/Footer.js' 
import feature1 from '../image/feature1.png'
import feature2 from '../image/feature2.png'
import feature3 from '../image/feature3.png'
import feature4 from '../image/feature4.png'

import dccDb1Img from "./products/images/digitalWorkSpace1.png"
import dccDb2Img from "./products/images/digitalWorkSpace2.png"

import OmniDb1Img from "../image/omni/omniDb1.png"
import OmniDb2Img from "../image/omni/omniDb2.png"
import OmniDb3Img from "../image/omni/omniDb3.png"

import coBrowserDb1Img from "./products/images/coBrowseDb1.png"
import coBrowserDb2Img from "./products/images/coBrowseDb2.png"
import coBrowserDb3Img from "./products/images/coBrowseDb3.png"


import OtherDb1Img from "../image/other-service/otherDb1Img.png"
import OtherDb2Img from "../image/other-service/otherDb2Img.png"
import OtherDb3Img from "../image/other-service/otherDb3Img.png"
import OtherDb4Img from "../image/other-service/otherDb4Img.png"

function Feature() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <Topbar />
            <div className='mt-[120px] mb-[73px] top_section feature-page'>
                <h2 className='text-center lg:font-semibold font-medium' style={{textTransform:"none"}}>Transforming Customer Experience <br /> with Intelligent <span className='text-[#006FFF]'> Omnichannel</span> Engagement Platform!​</h2>
                <p className='mt-5 w-full md:w-[70%] mx-auto text-center text-[#797979]' style={{textAlign:"center"}}>Modernize customer experience with digital assistants, chatbots, robot, social messaging channels, smart routing, social listening & analytics providing a unique and outstanding experience. ​</p>
                <div className='mt-[70px] lg:mt-[90px] top_section'>
                    <div className='flex lg:flex-row flex-col-reverse items-center lg:space-x-16'>
                        <div className="md:w-[60%] w-full h-[200px] relative md:mt-0 mt-5">
                            <div className="absolute left-0 w-[60%] zoom-image">
                                <img src={dccDb2Img} alt="" />
                            </div>
                            <div className="absolute right-[0%] top-1/3 w-[60%] zoom-image">
                                <img src={dccDb1Img} alt="" />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-1/2'>
                            <h3 className='lg:font-semibold font-medium'>Maximize the possibilities of the platforms you already have​</h3>
                            <p className='pt-8 text-[#797979]'>Inaipi DCC connects with the top CRMs, e-commerce platforms, help desk applications, and marketing tools. You don't have to worry about the coding because we did it. ​</p>
                        </div>
                    </div>
                </div>
                <div className='mt-[70px] lg:mt-[90px] top_section'>
                    <div className='flex lg:flex-row flex-col items-center lg:space-x-16'>
                        <div className='flex flex-col w-full lg:w-1/2'>
                            <h3 className='lg:font-semibold font-medium'>Revitalizing the Customer Experience with an Omnichannel Contact Center​​</h3>
                            <p className='pt-8 text-[#797979]'>With the Inaipi DCC solution, businesses can communicate with customers through multiple channels for a seamless experience and gain insights into their behavior. Customers can choose their preferred method, leading to higher satisfaction levels. Additionally, the solution centralizes customer interactions to boost agent efficiency and enable them to handle multiple queries with ease​​​.</p>
                        </div>
                        <div className="md:w-[50%] w-full h-[200px] relative md:mt-0 mt-5">
                            <div className="absolute left-0 w-[60%] zoom-image">
                                <img src={OmniDb1Img} alt="" />
                            </div>
                            <div className="absolute right-[20%] top-1/4 w-[60%] zoom-image">
                                <img src={OmniDb2Img} alt="" />
                            </div>
                            <div className="absolute right-[0%] top-1/2 w-[60%] zoom-image">
                                <img src={OmniDb3Img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-[70px] lg:mt-[90px] top_section'>
                    <div className='flex lg:flex-row flex-col-reverse items-center lg:space-x-16'>
                        <div className="md:w-[50%] w-full h-[200px] relative md:mt-0 mt-5">
                            <div className="absolute left-0 w-[60%] zoom-image border">
                                <img src={coBrowserDb1Img} alt="" />
                            </div>
                            <div className="absolute right-[20%] top-[20%] w-[60%] zoom-image border">
                                <img src={coBrowserDb2Img} alt="" />
                            </div>
                            <div className="absolute right-[0%] top-[66%] w-[60%] zoom-image border">
                                <img src={coBrowserDb3Img} alt="" />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-1/2'>
                            <h3 className='lg:font-semibold font-medium'>Co-browsing for seamless collaboration​​</h3>
                            <p className='pt-8 text-[#797979]'>Maximize your remote interactions with crystal-clear clarity and gain valuable insight into your customer's screen for real-time application viewing. Cobrowsing can be easily adapted to align with your existing agent and end-user workflows, without disrupting the flow ​​</p>
                        </div>
                    </div>
                </div>
                <div className='mt-[70px] lg:mt-[130px] top_section'>
                    <div className='flex lg:flex-row flex-col items-center lg:space-x-16'>
                        <div className='flex flex-col w-full lg:w-1/2'>
                            <h3 className='lg:font-semibold font-medium'>Handle all customer enquiries from a single location​</h3>
                            <p className='pt-8 text-[#797979]'>Streamline all customer interactions with an integrated omnichannel digital platform. Stay accessible to customers through email, and chat, while offering value-added features like ticketing tools, knowledge base, customer surveys, and more.​</p>
                        </div>
                       
                        <img src={OtherDb1Img} alt='Landing3' className='lg:w-1/2 mt-5 lg:mt-0  fea' />
                    </div>
                </div>
                <div className='mt-[70px] lg:mt-[90px] top_section'>
                    <div className='flex lg:flex-row flex-col-reverse items-center lg:space-x-16'>
                         <div className="md:w-[60%] w-full h-[200px] relative md:mt-0 mt-5">
                            <div className="absolute left-0 w-[60%] zoom-image">
                                <img src={OtherDb3Img} alt="" />
                            </div>
                            <div className="absolute right-[10%] top-1/3 w-[60%] zoom-image">
                                <img src={OtherDb2Img} alt="" />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-1/2'>
                            <h3 className='lg:font-semibold font-medium'>Streamline your customer service with minimal effort through automation​​​</h3>
                            <p className='pt-8 text-[#797979]'>Efficiently automate service processes such as appointment bookings and scheduling using intelligent chatbots that operate across multiple channels. Our AI chatbot is a highly advanced conversational software application capable of communicating with your customers in their preferred language.​​</p>
                        </div>
                    </div>
                </div>
                <div className='mt-[70px] lg:mt-[90px] top_section'>
                    <div className='flex lg:flex-row flex-col items-center lg:space-x-16'>
                        <div className='flex flex-col w-full lg:w-1/2'>
                            <h3 className='lg:font-semibold font-medium'>Facilitate multi-platform communication with customers seamlessly​​​</h3>
                            <p className='pt-8 text-[#797979]'>Effortlessly manage all customer inquiries, requests, and complaints from various sources, including social media, your website, SMS, and email, all within a single platform. With this integrated system, you can ensure that no customer request is lost and maintain exceptional customer relationships.​​</p>
                        </div>
                        <img src={OtherDb4Img} alt='Landing4' className='lg:w-1/2  mt-5 lg:mt-0 fea' />
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    )
}

export default Feature
