import React from "react";
import PressCover from "./images/pressCover.png"

function PressHead(props) {
  const { coverPic, heading } = props;
  return (
    <div
      className="blog-head h-[240px] lg:mt-[74px] mt-[68px] w-full flex items-center relative"
      style={{
        background: `url(${PressCover})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute left-0 top-0 w-full h-full bg-black opacity-50"></div>
      <div className="flex top_section items-center h-full w-full relative">
        <h1 className="text-white">PRESS RELEASES</h1>
      </div>
    </div>
  );
}

export default PressHead;
