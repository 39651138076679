import React, { useEffect } from "react";
import TopNav from "../../bars/TopNav";
import Footer from "../../bars/Footer";
import productHome from "../../image/productsHome.png";
import Card from "./components/Card";
import { products_card_data } from "./constants/arrays";

function ProductNew() {

  return (
    <div>
      <TopNav />
      <div className="top_section head w-full mt-24">
        <div data-aos="fade-up"  className="head1 mr-0 lg:mr-10">
          <h1 className="">
            <span className="mr-3"> How We Transform Your</span>
            <span className="text-[#006FFF]">
              Customer Journey Experience
            </span>{" "}
          </h1>
          <p className="pt-4 text-[#6A6A6A]">
            Customer Engagement Platform to modernize customer experience
            through all the channels including voice, digital assistants,
            chatbots, social messaging channels, smart routing, social listening
            & analytics providing a unique and outstanding experience.
          </p>
        </div>
        <img
          data-aos="fade-up" 
          src={productHome}
          className="careerImg mt-4 lg:mt-0 ml-4 "
          alt="products"
        />
      </div>
      
      <div className="w-full bg-[#FBFBFB] mt-10 rounded-t-lg">
        <div className="top_section pt-10 grid grid-flow-row xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2  grid-cols-1  gap-5 lg:mt-6 mt-3 pb-32 products-grid">
          {products_card_data.map((item, i) => (
            <Card key={i} image={item.image} head={item.head} para={item.para} link={item.link}/>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProductNew;
