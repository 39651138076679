import React from "react";
import { Link } from "react-router-dom";

function BlogCard(props) {
  const { data } = props;
  return (
    <Link to={data?.link} className="blog-card relative">
      {/* <img className="w-full h-10 bg-red-400" src="" alt="" /> */}
      <div
        className="bg-red-500 w-full h-[120px] bg-cover bg-no-repeat"
        style={{
          background: `url(${data?.image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="content">
        <div>{data?.title}</div>
        <div className="absolute bottom-3 right-3 date">{data?.date}</div>
      </div>
    </Link>
  );
}

export default BlogCard;
