import React from "react";
import CareerImg3 from "../image/ImgCareer3.png";
import CareerImg4 from "../image/ImgCareer4.png";

function CareerB() {
  return (
    <div className="top_section my-20 flex flex-col space-y-10">
      <div className="flex justify-center w-fit lg:text-left text-center  flex-col lg:flex-row items-center space-x-0 lg:space-x-12 rounded-sm">
        <img
          className="w-[390px] h-[400px] fea"
          src={CareerImg3}
          alt="CareerImg3"
        />
        <div className="w-full lg:w-1/2">
          <h2 className="lg:mt-0 mt-5">Who we are</h2>
          <p className="mt-4 text-[#6A6A6A]">
            Inaipi is a leading provider of SaaS solutions for customer service
            and support. Our mission is to empower businesses to deliver
            exceptional customer experiences, build stronger relationships, and
            drive growth. With our cutting-edge technology and dedication to
            innovation, we are transforming the way companies engage with their
            customers.
          </p>
        </div>
      </div>

      <div className="flex  justify-center w-fit lg:text-left text-center  flex-col lg:flex-row-reverse items-center space-x-0 lg:space-x-12 rounded-sm">
        <img
          className="sm:w-[390px] sm:h-[400px] w-[343px] h-[292px] ml-0 lg:ml-10 fea"
          src={CareerImg4}
          alt="CareerImg3"
        />
        <div className="w-full lg:w-1/2 mr-0 lg:mr-10">
          <h2 className="lg:mt-0 mt-5">Our Culture</h2>
          <p className="mt-4 text-[#6A6A6A]">
          At Inaipi, we foster a culture of collaboration, creativity, and continuous learning. We value diversity and believe in creating an inclusive workplace where everyone's ideas are heard and respected. We encourage open communication, teamwork, and a supportive atmosphere that enables our employees to reach their full potential.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CareerB;
