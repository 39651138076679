import React from 'react'
import CareerImg2 from '../image/ImgCareer2.png'
import ImgCareerC from '../image/ImgCareerC.png'

function CareerC() {
  return (
    <div className='careerCbg'>
      <div className='top_section careerC space-x-0 lg:space-x-6 w-full'>
        <div className='flex items-center justify-center lg:justify-start w-full lg:w-[40%]'>
          <img className='lg:block hidden lg:mt-0 shrink-0' src={ImgCareerC} />
          <img className='block lg:hidden mt-8 w-[344px] shrink-0' src={CareerImg2} />
        </div>
        <div className='flex flex-col text-[#FFF] lg:py-20 p-0 pb-8 w-full lg:w-1/2'>
          <h2 className='text-[#FFF]'>Why Join Inaipi?</h2>
          <div className='flex space-x-2 mt-4 w-full lg:w-[85%]'>
            <div className='shrink-0 w-1 h-1 mt-2 bg-white rounded-full'></div>
            <p><span className='font-bold text-[18px]'>Competitive Compensation:</span> We offer competitive salaries and comprehensive benefits packages to reward our talented team members.</p>
          </div>
          <div className='flex space-x-2 mt-4 w-full lg:w-[85%]'>
            <div className='shrink-0 w-1 h-1 mt-2 bg-white rounded-full'></div>
            <p><span className='font-bold text-[18px]'>Growth and Development:</span> We are committed to nurturing your professional growth through mentorship programs, training opportunities, and career advancement prospects.</p>
          </div>
          <div className='flex space-x-2 mt-4 w-full lg:w-[85%]'>
            <div className='shrink-0 w-1 h-1 mt-2 bg-white rounded-full'></div>
            <p><span className='font-bold text-[18px]'>Innovation and Impact:</span> Join a team that is at the forefront of innovation in the customer service industry. Your work at INAIPI will have a direct impact on shaping the future of customer experience.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareerC
