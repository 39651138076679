import React, { useEffect, useState } from 'react';
import Datatable from './Datatable';
import DataLogin from './DataLogin';

function Enquiries() {

  const [loggedIn, setLoggedIn] = useState('false');

  useEffect(() =>{
    const inaipi = localStorage.getItem('inaipiLog');
    if (inaipi === 'true') {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const onLogin = () => {
    setLoggedIn(true);
    localStorage.setItem('inaipiLog', true);
  };


  return (
    <div>
      {loggedIn ? (
        <Datatable />
      ) : (
        <DataLogin onLogin={onLogin} />
      )}
    </div>
  );
}

export default Enquiries;
