export const sign_up_animation = {
  v: "5.5.7",
  meta: {
    g: "LottieFiles AE 0.1.21",
    a: "Abdur Rahim",
    k: "",
    d: "Animation Made by uxpuzzle.com",
    tc: "",
  },
  fr: 30,
  ip: 0,
  op: 120,
  w: 500,
  h: 500,
  nm: "Rander",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: "mail (1)",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [1], y: [0] },
                  t: 80,
                  s: [0],
                },
                { t: 90, s: [-179] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 1, y: 0 },
                  t: 0,
                  s: [512, 384, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 15,
                  s: [512, 344, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 30,
                  s: [512, 384, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 50,
                  s: [512, 384, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 60,
                  s: [512, 344, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.167, y: 0.167 },
                  t: 75,
                  s: [512, 384, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 1, y: 0 },
                  t: 80,
                  s: [512, 384, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 87,
                  s: [512, 344, 0],
                  to: null,
                  ti: null,
                },
                { t: 90, s: [512, 384, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [424, 424, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                  t: 80,
                  s: [100, 100, 100],
                },
                { t: 90, s: [0, 0, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          w: 848,
          h: 848,
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
          completed: true,
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "Layer 8 Outlines",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [427.74, 466.4, 0], ix: 2 },
                a: { a: 0, k: [180.05, 122.05, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [179.8, -114.6],
                            [179.8, 92.6],
                            [167, 121.8],
                            [-150.6, 121.8],
                            [-179.8, 109],
                            [-179.8, -111],
                            [-179.4, -118.2],
                            [-32.2, -7.8],
                            [13, 6.6000000000000005],
                            [177.8, -121.8],
                          ],
                          o: [
                            [179.8, -111],
                            [179.8, 108.6],
                            [150.6, 121.8],
                            [-166.6, 121.8],
                            [-179.8, 92.6],
                            [-179.8, -114.6],
                            [-177.8, -121.8],
                            [-13.400000000000002, 6.6000000000000005],
                            [31.8, -7.8],
                            [179, -118.6],
                          ],
                          v: [
                            [179.8, -111],
                            [179.8, 92.6],
                            [150.6, 121.8],
                            [-150.6, 121.8],
                            [-179.8, 92.6],
                            [-179.8, -111],
                            [-177.8, -121.8],
                            [-32.2, -7.8],
                            [31.8, -7.8],
                            [177.8, -121.8],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [180.05, 122.05], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "paper mask",
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [424, 424, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [90.598, 98.182, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [436, 306], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [16, -67], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 0,
              nm: "paper",
              tt: 1,
              refId: "comp_2",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.834, y: 0.732 },
                      o: { x: 0.547, y: 0 },
                      t: 19,
                      s: [424, 309, 0],
                      to: null,
                      ti: null,
                    },
                    {
                      i: { x: 0.834, y: 0.26 },
                      o: { x: 0.224, y: 1 },
                      t: 38,
                      s: [424, 693.494, 0],
                      to: null,
                      ti: null,
                    },
                    {
                      i: { x: 0.384, y: 1 },
                      o: { x: 0.56, y: 0.756 },
                      t: 59,
                      s: [424, 635.494, 0],
                      to: null,
                      ti: null,
                    },
                    { t: 66, s: [424, 805, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [424, 424, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              w: 848,
              h: 848,
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Shape Layer 9",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 208.168, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Shape Layer 8",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 185.418, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Shape Layer 7",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 162.668, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 146,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Shape Layer 6",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 139.918, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 145,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Shape Layer 5",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 117.168, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Shape Layer 4",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 94.418, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 7,
                  ty: 4,
                  nm: "Shape Layer 3",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 71.668, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 146,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 8,
                  ty: 4,
                  nm: "Shape Layer 2",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 48.918, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 9,
                  ty: 4,
                  nm: "Shape Layer 1",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 26.168, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 146,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 10,
                  ty: 4,
                  nm: "Paper",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [427.742, 206.454, 0], ix: 2 },
                    a: { a: 0, k: [128.211, 113.24, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [118.86500000000001, 112.99],
                                [-107.643, 112.99],
                                [-127.96, 103.894],
                                [-127.96, -92.673],
                                [-118.864, -112.99],
                                [107.644, -112.99],
                                [127.96, -103.894],
                                [127.96, 92.673],
                              ],
                              o: [
                                [107.644, 112.99],
                                [-118.864, 112.99],
                                [-127.96, 92.673],
                                [-127.96, -103.894],
                                [-107.643, -112.99],
                                [118.86500000000001, -112.99],
                                [127.96, -92.673],
                                [127.96, 103.894],
                              ],
                              v: [
                                [107.644, 112.99],
                                [-107.643, 112.99],
                                [-127.96, 92.673],
                                [-127.96, -92.673],
                                [-107.643, -112.99],
                                [107.644, -112.99],
                                [127.96, -92.673],
                                [127.96, 92.673],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.9145, 0.9855, 0.9784, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [128.21, 113.24], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
              ],
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "Cap",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [427.865, 345.49, 0], ix: 2 },
                a: { a: 0, k: [177.945, 128.36, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 1, 0.333], y: [0, 0, 0] },
                      t: 6,
                      s: [100, -101, 100],
                    },
                    {
                      i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 24,
                      s: [100, 100, 100],
                    },
                    {
                      i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 47,
                      s: [100, 100, 100],
                    },
                    {
                      i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 53,
                      s: [100, 113, 100],
                    },
                    {
                      i: { x: [0.667, 0, 0.667], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 59,
                      s: [100, 100, 100],
                    },
                    { t: 75, s: [100, -101, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [177.57, 64.11],
                            [-177.57, 64.11],
                            [-32.2, -49.71],
                            [13, -64.11],
                          ],
                          o: [
                            [177.57, 64.11],
                            [-177.57, 64.11],
                            [-13.400000000000002, -64.11],
                            [31.8, -49.71],
                          ],
                          v: [
                            [177.57, 64.11],
                            [-177.57, 64.11],
                            [-32.2, -49.71],
                            [31.8, -49.71],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.2993, 0.4395, 0.5207, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [177.82, 64.36], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 147,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "Layer 1 Outlines",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [428.74, 444.532, 0], ix: 2 },
                a: { a: 0, k: [179.05, 100.182, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [178.8, 99.932],
                            [-178.8, 99.932],
                            [-178.8, -99.932],
                            [176.802, -99.75],
                          ],
                          o: [
                            [178.8, 99.932],
                            [-178.8, 99.932],
                            [-178.8, -99.932],
                            [176.802, -99.75],
                          ],
                          v: [
                            [178.8, 99.932],
                            [-178.8, 99.932],
                            [-178.8, -99.932],
                            [176.802, -99.75],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.2695, 0.3715, 0.4305, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [179.05, 100.182], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 147,
              st: 0,
              bm: 0,
              completed: true,
            },
          ],
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: "check (1)",
          refId: "comp_3",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [512, 384, 0], ix: 2 },
            a: { a: 0, k: [452.5, 452.5, 0], ix: 1 },
            s: { a: 0, k: [40, 40, 100], ix: 6 },
          },
          ao: 0,
          w: 905,
          h: 905,
          ip: 78,
          op: 198,
          st: 78,
          bm: 0,
          completed: true,
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "Layer 1 Outlines 2",
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.838, y: 0.896 },
                      o: { x: 0.951, y: 0 },
                      t: 0,
                      s: [583.5, 455.5, 0],
                      to: [0, -6.373, 0],
                      ti: [-10.44, -24.391, 0],
                    },
                    {
                      i: { x: 0.543, y: 1 },
                      o: { x: 0.233, y: 0.039 },
                      t: 15,
                      s: [458.463, 464.899, 0],
                      to: [0.482, 1.125, 0],
                      ti: [-0.581, -1.507, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [460.5, 396.5, 0],
                      to: [13.167, 34.167, 0],
                      ti: [1.167, -4.167, 0],
                    },
                    { t: 25, s: [453.5, 421.5, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [256, 256, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      t: 15,
                      s: [110, 110, 100],
                    },
                    { t: 24, s: [100, 100, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [141.164, -256],
                            [-256, -141.164],
                            [-141.164, 256],
                            [256, 141.164],
                          ],
                          o: [
                            [-141.164, -256],
                            [-256, 141.164],
                            [141.164, 256],
                            [256, -141.164],
                          ],
                          v: [
                            [0, -256],
                            [-256, 0],
                            [0, 256],
                            [256, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.2784, 0.8353, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [256, 256], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "Check Outlines",
              parent: 3,
              tt: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 1, y: 0 },
                      t: 0,
                      s: [424, 424, 0],
                      to: [-21.167, 25.667, 0],
                      ti: [25.667, 26.833, 0],
                    },
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 11,
                      s: [297, 578, 0],
                      to: [-25.667, -26.833, 0],
                      ti: [4.5, 52.5, 0],
                    },
                    { t: 25, s: [270, 263, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [266.667, 255.998, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      t: 15,
                      s: [120, 120, 100],
                    },
                    { t: 24, s: [100, 100, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [127.424, -61.549],
                            [-19.584, 85.459],
                            [-29.205, 91.713],
                            [-45.587999999999994, 89.619],
                            [-119.08, 16.127],
                            [-127.42399999999999, -5.697000000000001],
                            [-97.25999999999999, -22.381],
                            [-34.666, 40.213],
                            [88.92, -83.369],
                            [110.74000000000001, -91.713],
                          ],
                          o: [
                            [119.084, -53.205],
                            [-23.744, 89.619],
                            [-40.126999999999995, 91.713],
                            [-49.748, 85.459],
                            [-127.42399999999999, 7.786999999999999],
                            [-110.74, -22.381],
                            [-88.916, -14.037],
                            [-34.666, 40.213],
                            [97.26, -91.713],
                            [127.424, -75.029],
                          ],
                          v: [
                            [119.084, -53.205],
                            [-19.584, 85.459],
                            [-34.666, 91.713],
                            [-49.748, 85.459],
                            [-119.08, 16.127],
                            [-119.08, -14.037],
                            [-88.916, -14.037],
                            [-34.666, 40.213],
                            [88.92, -83.369],
                            [119.084, -83.369],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.98, 0.98, 0.98, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [266.666, 254.955], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "Layer 1 Outlines",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.838, y: 0.896 },
                      o: { x: 0.951, y: 0 },
                      t: 0,
                      s: [583.5, 455.5, 0],
                      to: [0, -6.373, 0],
                      ti: [-10.44, -24.391, 0],
                    },
                    {
                      i: { x: 0.543, y: 1 },
                      o: { x: 0.233, y: 0.039 },
                      t: 15,
                      s: [458.463, 464.899, 0],
                      to: [0.482, 1.125, 0],
                      ti: [-0.581, -1.507, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [460.5, 396.5, 0],
                      to: [13.167, 34.167, 0],
                      ti: [1.167, -4.167, 0],
                    },
                    { t: 25, s: [453.5, 421.5, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [256, 256, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      t: 15,
                      s: [110, 110, 100],
                    },
                    { t: 24, s: [100, 100, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [141.164, -256],
                            [-256, -141.164],
                            [-141.164, 256],
                            [256, 141.164],
                          ],
                          o: [
                            [-141.164, -256],
                            [-256, 141.164],
                            [141.164, 256],
                            [256, -141.164],
                          ],
                          v: [
                            [0, -256],
                            [-256, 0],
                            [0, 256],
                            [256, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [256, 256], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "Layer 1 Outlines 3",
              sr: 1,
              ks: {
                o: { a: 0, k: 20, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.838, y: 0.896 },
                      o: { x: 0.951, y: 0 },
                      t: 0,
                      s: [583.5, 455.5, 0],
                      to: [0, -6.373, 0],
                      ti: [-10.44, -24.391, 0],
                    },
                    {
                      i: { x: 0.543, y: 1 },
                      o: { x: 0.233, y: 0.039 },
                      t: 15,
                      s: [458.463, 464.899, 0],
                      to: [0.482, 1.125, 0],
                      ti: [-0.581, -1.507, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [460.5, 396.5, 0],
                      to: [13.167, 34.167, 0],
                      ti: [1.167, -4.167, 0],
                    },
                    { t: 25, s: [453.5, 421.5, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [256, 256, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      t: 15,
                      s: [110, 110, 100],
                    },
                    {
                      i: { x: [0, 0, 0.833], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 21,
                      s: [100, 100, 100],
                    },
                    { t: 26, s: [130, 130, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [141.164, -256],
                            [-256, -141.164],
                            [-141.164, 256],
                            [256, 141.164],
                          ],
                          o: [
                            [-141.164, -256],
                            [-256, 141.164],
                            [141.164, 256],
                            [256, -141.164],
                          ],
                          v: [
                            [0, -256],
                            [-256, 0],
                            [0, 256],
                            [256, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [256, 256], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "Layer 1 Outlines 4",
              sr: 1,
              ks: {
                o: { a: 0, k: 10, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.838, y: 0.896 },
                      o: { x: 0.951, y: 0 },
                      t: 0,
                      s: [583.5, 455.5, 0],
                      to: [0, -6.373, 0],
                      ti: [-10.44, -24.391, 0],
                    },
                    {
                      i: { x: 0.543, y: 1 },
                      o: { x: 0.233, y: 0.039 },
                      t: 15,
                      s: [458.463, 464.899, 0],
                      to: [0.482, 1.125, 0],
                      ti: [-0.581, -1.507, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [460.5, 396.5, 0],
                      to: [13.167, 34.167, 0],
                      ti: [1.167, -4.167, 0],
                    },
                    { t: 25, s: [453.5, 421.5, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [256, 256, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      t: 15,
                      s: [110, 110, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 21,
                      s: [100, 100, 100],
                    },
                    {
                      i: { x: [0, 0, 0.833], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 26,
                      s: [100, 100, 100],
                    },
                    { t: 32, s: [160, 160, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [141.164, -256],
                            [-256, -141.164],
                            [-141.164, 256],
                            [256, 141.164],
                          ],
                          o: [
                            [-141.164, -256],
                            [-256, 141.164],
                            [141.164, 256],
                            [256, -141.164],
                          ],
                          v: [
                            [0, -256],
                            [-256, 0],
                            [0, 256],
                            [256, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [256, 256], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
          ],
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Layer 8 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [427.74, 466.4, 0], ix: 2 },
            a: { a: 0, k: [180.05, 122.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [179.8, -114.6],
                        [179.8, 92.6],
                        [167, 121.8],
                        [-150.6, 121.8],
                        [-179.8, 109],
                        [-179.8, -111],
                        [-179.4, -118.2],
                        [-32.2, -7.8],
                        [13, 6.6000000000000005],
                        [177.8, -121.8],
                      ],
                      o: [
                        [179.8, -111],
                        [179.8, 108.6],
                        [150.6, 121.8],
                        [-166.6, 121.8],
                        [-179.8, 92.6],
                        [-179.8, -114.6],
                        [-177.8, -121.8],
                        [-13.400000000000002, 6.6000000000000005],
                        [31.8, -7.8],
                        [179, -118.6],
                      ],
                      v: [
                        [179.8, -111],
                        [179.8, 92.6],
                        [150.6, 121.8],
                        [-150.6, 121.8],
                        [-179.8, 92.6],
                        [-179.8, -111],
                        [-177.8, -121.8],
                        [-32.2, -7.8],
                        [31.8, -7.8],
                        [177.8, -121.8],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [180.05, 122.05], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "paper mask",
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [424, 424, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [90.598, 98.182, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [436, 306], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [16, -67], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          nm: "paper",
          tt: 1,
          refId: "comp_2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.834, y: 0.732 },
                  o: { x: 0.547, y: 0 },
                  t: 19,
                  s: [424, 309, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.834, y: 0.26 },
                  o: { x: 0.224, y: 1 },
                  t: 38,
                  s: [424, 693.494, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.384, y: 1 },
                  o: { x: 0.56, y: 0.756 },
                  t: 59,
                  s: [424, 635.494, 0],
                  to: null,
                  ti: null,
                },
                { t: 66, s: [424, 805, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [424, 424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 848,
          h: 848,
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
          completed: true,
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "Shape Layer 9",
              parent: 10,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [130.969, 208.168, 0], ix: 2 },
                a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: "Fill",
                  np: 9,
                  mn: "ADBE Fill",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: "Fill Mask",
                      mn: "ADBE Fill-0001",
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: "All Masks",
                      mn: "ADBE Fill-0007",
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: "Color",
                      mn: "ADBE Fill-0002",
                      ix: 3,
                      v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                    },
                    {
                      ty: 7,
                      nm: "Invert",
                      mn: "ADBE Fill-0006",
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: "Horizontal Feather",
                      mn: "ADBE Fill-0003",
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: "Vertical Feather",
                      mn: "ADBE Fill-0004",
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Fill-0005",
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [356, 100], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [38, 18], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 147,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "Shape Layer 8",
              parent: 10,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [130.969, 185.418, 0], ix: 2 },
                a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: "Fill",
                  np: 9,
                  mn: "ADBE Fill",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: "Fill Mask",
                      mn: "ADBE Fill-0001",
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: "All Masks",
                      mn: "ADBE Fill-0007",
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: "Color",
                      mn: "ADBE Fill-0002",
                      ix: 3,
                      v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                    },
                    {
                      ty: 7,
                      nm: "Invert",
                      mn: "ADBE Fill-0006",
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: "Horizontal Feather",
                      mn: "ADBE Fill-0003",
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: "Vertical Feather",
                      mn: "ADBE Fill-0004",
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Fill-0005",
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [356, 100], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [38, 18], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 147,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "Shape Layer 7",
              parent: 10,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [130.969, 162.668, 0], ix: 2 },
                a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: "Fill",
                  np: 9,
                  mn: "ADBE Fill",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: "Fill Mask",
                      mn: "ADBE Fill-0001",
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: "All Masks",
                      mn: "ADBE Fill-0007",
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: "Color",
                      mn: "ADBE Fill-0002",
                      ix: 3,
                      v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                    },
                    {
                      ty: 7,
                      nm: "Invert",
                      mn: "ADBE Fill-0006",
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: "Horizontal Feather",
                      mn: "ADBE Fill-0003",
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: "Vertical Feather",
                      mn: "ADBE Fill-0004",
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Fill-0005",
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [356, 100], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [38, 18], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 146,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "Shape Layer 6",
              parent: 10,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [130.969, 139.918, 0], ix: 2 },
                a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: "Fill",
                  np: 9,
                  mn: "ADBE Fill",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: "Fill Mask",
                      mn: "ADBE Fill-0001",
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: "All Masks",
                      mn: "ADBE Fill-0007",
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: "Color",
                      mn: "ADBE Fill-0002",
                      ix: 3,
                      v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                    },
                    {
                      ty: 7,
                      nm: "Invert",
                      mn: "ADBE Fill-0006",
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: "Horizontal Feather",
                      mn: "ADBE Fill-0003",
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: "Vertical Feather",
                      mn: "ADBE Fill-0004",
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Fill-0005",
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [356, 100], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [38, 18], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 145,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "Shape Layer 5",
              parent: 10,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [130.969, 117.168, 0], ix: 2 },
                a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: "Fill",
                  np: 9,
                  mn: "ADBE Fill",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: "Fill Mask",
                      mn: "ADBE Fill-0001",
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: "All Masks",
                      mn: "ADBE Fill-0007",
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: "Color",
                      mn: "ADBE Fill-0002",
                      ix: 3,
                      v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                    },
                    {
                      ty: 7,
                      nm: "Invert",
                      mn: "ADBE Fill-0006",
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: "Horizontal Feather",
                      mn: "ADBE Fill-0003",
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: "Vertical Feather",
                      mn: "ADBE Fill-0004",
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Fill-0005",
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [356, 100], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [38, 18], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 147,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 4,
              nm: "Shape Layer 4",
              parent: 10,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [130.969, 94.418, 0], ix: 2 },
                a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: "Fill",
                  np: 9,
                  mn: "ADBE Fill",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: "Fill Mask",
                      mn: "ADBE Fill-0001",
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: "All Masks",
                      mn: "ADBE Fill-0007",
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: "Color",
                      mn: "ADBE Fill-0002",
                      ix: 3,
                      v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                    },
                    {
                      ty: 7,
                      nm: "Invert",
                      mn: "ADBE Fill-0006",
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: "Horizontal Feather",
                      mn: "ADBE Fill-0003",
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: "Vertical Feather",
                      mn: "ADBE Fill-0004",
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Fill-0005",
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [356, 100], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [38, 18], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 147,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 4,
              nm: "Shape Layer 3",
              parent: 10,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [130.969, 71.668, 0], ix: 2 },
                a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: "Fill",
                  np: 9,
                  mn: "ADBE Fill",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: "Fill Mask",
                      mn: "ADBE Fill-0001",
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: "All Masks",
                      mn: "ADBE Fill-0007",
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: "Color",
                      mn: "ADBE Fill-0002",
                      ix: 3,
                      v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                    },
                    {
                      ty: 7,
                      nm: "Invert",
                      mn: "ADBE Fill-0006",
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: "Horizontal Feather",
                      mn: "ADBE Fill-0003",
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: "Vertical Feather",
                      mn: "ADBE Fill-0004",
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Fill-0005",
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [356, 100], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [38, 18], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 146,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 4,
              nm: "Shape Layer 2",
              parent: 10,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [130.969, 48.918, 0], ix: 2 },
                a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: "Fill",
                  np: 9,
                  mn: "ADBE Fill",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: "Fill Mask",
                      mn: "ADBE Fill-0001",
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: "All Masks",
                      mn: "ADBE Fill-0007",
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: "Color",
                      mn: "ADBE Fill-0002",
                      ix: 3,
                      v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                    },
                    {
                      ty: 7,
                      nm: "Invert",
                      mn: "ADBE Fill-0006",
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: "Horizontal Feather",
                      mn: "ADBE Fill-0003",
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: "Vertical Feather",
                      mn: "ADBE Fill-0004",
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Fill-0005",
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [356, 100], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [38, 18], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 147,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 4,
              nm: "Shape Layer 1",
              parent: 10,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [130.969, 26.168, 0], ix: 2 },
                a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
              },
              ao: 0,
              ef: [
                {
                  ty: 21,
                  nm: "Fill",
                  np: 9,
                  mn: "ADBE Fill",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: "Fill Mask",
                      mn: "ADBE Fill-0001",
                      ix: 1,
                      v: { a: 0, k: 0, ix: 1 },
                    },
                    {
                      ty: 7,
                      nm: "All Masks",
                      mn: "ADBE Fill-0007",
                      ix: 2,
                      v: { a: 0, k: 0, ix: 2 },
                    },
                    {
                      ty: 2,
                      nm: "Color",
                      mn: "ADBE Fill-0002",
                      ix: 3,
                      v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                    },
                    {
                      ty: 7,
                      nm: "Invert",
                      mn: "ADBE Fill-0006",
                      ix: 4,
                      v: { a: 0, k: 0, ix: 4 },
                    },
                    {
                      ty: 0,
                      nm: "Horizontal Feather",
                      mn: "ADBE Fill-0003",
                      ix: 5,
                      v: { a: 0, k: 0, ix: 5 },
                    },
                    {
                      ty: 0,
                      nm: "Vertical Feather",
                      mn: "ADBE Fill-0004",
                      ix: 6,
                      v: { a: 0, k: 0, ix: 6 },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Fill-0005",
                      ix: 7,
                      v: { a: 0, k: 1, ix: 7 },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [356, 100], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [38, 18], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 146,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 4,
              nm: "Paper",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [427.742, 206.454, 0], ix: 2 },
                a: { a: 0, k: [128.211, 113.24, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [118.86500000000001, 112.99],
                            [-107.643, 112.99],
                            [-127.96, 103.894],
                            [-127.96, -92.673],
                            [-118.864, -112.99],
                            [107.644, -112.99],
                            [127.96, -103.894],
                            [127.96, 92.673],
                          ],
                          o: [
                            [107.644, 112.99],
                            [-118.864, 112.99],
                            [-127.96, 92.673],
                            [-127.96, -103.894],
                            [-107.643, -112.99],
                            [118.86500000000001, -112.99],
                            [127.96, -92.673],
                            [127.96, 103.894],
                          ],
                          v: [
                            [107.644, 112.99],
                            [-107.643, 112.99],
                            [-127.96, 92.673],
                            [-127.96, -92.673],
                            [-107.643, -112.99],
                            [107.644, -112.99],
                            [127.96, -92.673],
                            [127.96, 92.673],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.9145, 0.9855, 0.9784, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [128.21, 113.24], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 147,
              st: 0,
              bm: 0,
              completed: true,
            },
          ],
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Cap",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [427.865, 345.49, 0], ix: 2 },
            a: { a: 0, k: [177.945, 128.36, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 1, 0.333], y: [0, 0, 0] },
                  t: 6,
                  s: [100, -101, 100],
                },
                {
                  i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 24,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 47,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 53,
                  s: [100, 113, 100],
                },
                {
                  i: { x: [0.667, 0, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 59,
                  s: [100, 100, 100],
                },
                { t: 75, s: [100, -101, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [177.57, 64.11],
                        [-177.57, 64.11],
                        [-32.2, -49.71],
                        [13, -64.11],
                      ],
                      o: [
                        [177.57, 64.11],
                        [-177.57, 64.11],
                        [-13.400000000000002, -64.11],
                        [31.8, -49.71],
                      ],
                      v: [
                        [177.57, 64.11],
                        [-177.57, 64.11],
                        [-32.2, -49.71],
                        [31.8, -49.71],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.2993, 0.4395, 0.5207, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [177.82, 64.36], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 147,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Layer 1 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [428.74, 444.532, 0], ix: 2 },
            a: { a: 0, k: [179.05, 100.182, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [178.8, 99.932],
                        [-178.8, 99.932],
                        [-178.8, -99.932],
                        [176.802, -99.75],
                      ],
                      o: [
                        [178.8, 99.932],
                        [-178.8, 99.932],
                        [-178.8, -99.932],
                        [176.802, -99.75],
                      ],
                      v: [
                        [178.8, 99.932],
                        [-178.8, 99.932],
                        [-178.8, -99.932],
                        [176.802, -99.75],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.2695, 0.3715, 0.4305, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [179.05, 100.182], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 147,
          st: 0,
          bm: 0,
          completed: true,
        },
      ],
    },
    {
      id: "comp_2",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 9",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [130.969, 208.168, 0], ix: 2 },
            a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
            s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [356, 100], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [38, 18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 147,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 8",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [130.969, 185.418, 0], ix: 2 },
            a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
            s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [356, 100], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [38, 18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 147,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Shape Layer 7",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [130.969, 162.668, 0], ix: 2 },
            a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
            s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [356, 100], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [38, 18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 146,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Shape Layer 6",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [130.969, 139.918, 0], ix: 2 },
            a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
            s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [356, 100], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [38, 18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 145,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Shape Layer 5",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [130.969, 117.168, 0], ix: 2 },
            a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
            s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [356, 100], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [38, 18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 147,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Shape Layer 4",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [130.969, 94.418, 0], ix: 2 },
            a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
            s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [356, 100], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [38, 18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 147,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Shape Layer 3",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [130.969, 71.668, 0], ix: 2 },
            a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
            s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [356, 100], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [38, 18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 146,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Shape Layer 2",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [130.969, 48.918, 0], ix: 2 },
            a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
            s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [356, 100], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [38, 18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 147,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Shape Layer 1",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [130.969, 26.168, 0], ix: 2 },
            a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
            s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [356, 100], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [38, 18], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 146,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "Paper",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [427.742, 206.454, 0], ix: 2 },
            a: { a: 0, k: [128.211, 113.24, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [118.86500000000001, 112.99],
                        [-107.643, 112.99],
                        [-127.96, 103.894],
                        [-127.96, -92.673],
                        [-118.864, -112.99],
                        [107.644, -112.99],
                        [127.96, -103.894],
                        [127.96, 92.673],
                      ],
                      o: [
                        [107.644, 112.99],
                        [-118.864, 112.99],
                        [-127.96, 92.673],
                        [-127.96, -103.894],
                        [-107.643, -112.99],
                        [118.86500000000001, -112.99],
                        [127.96, -92.673],
                        [127.96, 103.894],
                      ],
                      v: [
                        [107.644, 112.99],
                        [-107.643, 112.99],
                        [-127.96, 92.673],
                        [-127.96, -92.673],
                        [-107.643, -112.99],
                        [107.644, -112.99],
                        [127.96, -92.673],
                        [127.96, 92.673],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.9145, 0.9855, 0.9784, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [128.21, 113.24], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 147,
          st: 0,
          bm: 0,
          completed: true,
        },
      ],
    },
    {
      id: "comp_3",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Layer 1 Outlines 2",
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.838, y: 0.896 },
                  o: { x: 0.951, y: 0 },
                  t: 0,
                  s: [583.5, 455.5, 0],
                  to: [0, -6.373, 0],
                  ti: [-10.44, -24.391, 0],
                },
                {
                  i: { x: 0.543, y: 1 },
                  o: { x: 0.233, y: 0.039 },
                  t: 15,
                  s: [458.463, 464.899, 0],
                  to: [0.482, 1.125, 0],
                  ti: [-0.581, -1.507, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 17,
                  s: [460.5, 396.5, 0],
                  to: [13.167, 34.167, 0],
                  ti: [1.167, -4.167, 0],
                },
                { t: 25, s: [453.5, 421.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [256, 256, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 15,
                  s: [110, 110, 100],
                },
                { t: 24, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [141.164, -256],
                        [-256, -141.164],
                        [-141.164, 256],
                        [256, 141.164],
                      ],
                      o: [
                        [-141.164, -256],
                        [-256, 141.164],
                        [141.164, 256],
                        [256, -141.164],
                      ],
                      v: [
                        [0, -256],
                        [-256, 0],
                        [0, 256],
                        [256, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.2784, 0.8353, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [256, 256], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Check Outlines",
          parent: 3,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 1, y: 0 },
                  t: 0,
                  s: [424, 424, 0],
                  to: [-21.167, 25.667, 0],
                  ti: [25.667, 26.833, 0],
                },
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 11,
                  s: [297, 578, 0],
                  to: [-25.667, -26.833, 0],
                  ti: [4.5, 52.5, 0],
                },
                { t: 25, s: [270, 263, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [266.667, 255.998, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 15,
                  s: [120, 120, 100],
                },
                { t: 24, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [127.424, -61.549],
                        [-19.584, 85.459],
                        [-29.205, 91.713],
                        [-45.587999999999994, 89.619],
                        [-119.08, 16.127],
                        [-127.42399999999999, -5.697000000000001],
                        [-97.25999999999999, -22.381],
                        [-34.666, 40.213],
                        [88.92, -83.369],
                        [110.74000000000001, -91.713],
                      ],
                      o: [
                        [119.084, -53.205],
                        [-23.744, 89.619],
                        [-40.126999999999995, 91.713],
                        [-49.748, 85.459],
                        [-127.42399999999999, 7.786999999999999],
                        [-110.74, -22.381],
                        [-88.916, -14.037],
                        [-34.666, 40.213],
                        [97.26, -91.713],
                        [127.424, -75.029],
                      ],
                      v: [
                        [119.084, -53.205],
                        [-19.584, 85.459],
                        [-34.666, 91.713],
                        [-49.748, 85.459],
                        [-119.08, 16.127],
                        [-119.08, -14.037],
                        [-88.916, -14.037],
                        [-34.666, 40.213],
                        [88.92, -83.369],
                        [119.084, -83.369],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.98, 0.98, 0.98, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [266.666, 254.955], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Layer 1 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.838, y: 0.896 },
                  o: { x: 0.951, y: 0 },
                  t: 0,
                  s: [583.5, 455.5, 0],
                  to: [0, -6.373, 0],
                  ti: [-10.44, -24.391, 0],
                },
                {
                  i: { x: 0.543, y: 1 },
                  o: { x: 0.233, y: 0.039 },
                  t: 15,
                  s: [458.463, 464.899, 0],
                  to: [0.482, 1.125, 0],
                  ti: [-0.581, -1.507, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 17,
                  s: [460.5, 396.5, 0],
                  to: [13.167, 34.167, 0],
                  ti: [1.167, -4.167, 0],
                },
                { t: 25, s: [453.5, 421.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [256, 256, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 15,
                  s: [110, 110, 100],
                },
                { t: 24, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [141.164, -256],
                        [-256, -141.164],
                        [-141.164, 256],
                        [256, 141.164],
                      ],
                      o: [
                        [-141.164, -256],
                        [-256, 141.164],
                        [141.164, 256],
                        [256, -141.164],
                      ],
                      v: [
                        [0, -256],
                        [-256, 0],
                        [0, 256],
                        [256, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [256, 256], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Layer 1 Outlines 3",
          sr: 1,
          ks: {
            o: { a: 0, k: 20, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.838, y: 0.896 },
                  o: { x: 0.951, y: 0 },
                  t: 0,
                  s: [583.5, 455.5, 0],
                  to: [0, -6.373, 0],
                  ti: [-10.44, -24.391, 0],
                },
                {
                  i: { x: 0.543, y: 1 },
                  o: { x: 0.233, y: 0.039 },
                  t: 15,
                  s: [458.463, 464.899, 0],
                  to: [0.482, 1.125, 0],
                  ti: [-0.581, -1.507, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 17,
                  s: [460.5, 396.5, 0],
                  to: [13.167, 34.167, 0],
                  ti: [1.167, -4.167, 0],
                },
                { t: 25, s: [453.5, 421.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [256, 256, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 15,
                  s: [110, 110, 100],
                },
                {
                  i: { x: [0, 0, 0.833], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 21,
                  s: [100, 100, 100],
                },
                { t: 26, s: [130, 130, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [141.164, -256],
                        [-256, -141.164],
                        [-141.164, 256],
                        [256, 141.164],
                      ],
                      o: [
                        [-141.164, -256],
                        [-256, 141.164],
                        [141.164, 256],
                        [256, -141.164],
                      ],
                      v: [
                        [0, -256],
                        [-256, 0],
                        [0, 256],
                        [256, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [256, 256], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
          completed: true,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Layer 1 Outlines 4",
          sr: 1,
          ks: {
            o: { a: 0, k: 10, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.838, y: 0.896 },
                  o: { x: 0.951, y: 0 },
                  t: 0,
                  s: [583.5, 455.5, 0],
                  to: [0, -6.373, 0],
                  ti: [-10.44, -24.391, 0],
                },
                {
                  i: { x: 0.543, y: 1 },
                  o: { x: 0.233, y: 0.039 },
                  t: 15,
                  s: [458.463, 464.899, 0],
                  to: [0.482, 1.125, 0],
                  ti: [-0.581, -1.507, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 17,
                  s: [460.5, 396.5, 0],
                  to: [13.167, 34.167, 0],
                  ti: [1.167, -4.167, 0],
                },
                { t: 25, s: [453.5, 421.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [256, 256, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 15,
                  s: [110, 110, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 21,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0, 0, 0.833], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 26,
                  s: [100, 100, 100],
                },
                { t: 32, s: [160, 160, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [141.164, -256],
                        [-256, -141.164],
                        [-141.164, 256],
                        [256, 141.164],
                      ],
                      o: [
                        [-141.164, -256],
                        [-256, 141.164],
                        [141.164, 256],
                        [256, -141.164],
                      ],
                      v: [
                        [0, -256],
                        [-256, 0],
                        [0, 256],
                        [256, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                  _render: true,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [256, 256], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                  _render: true,
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
              _render: true,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
          completed: true,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "Comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [250, 250, 0], ix: 2 },
        a: { a: 0, k: [512, 384, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1024,
      h: 768,
      ip: 0,
      op: 120,
      st: 0,
      bm: 0,
      completed: true,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: "mail (1)",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [1], y: [0] },
                  t: 80,
                  s: [0],
                },
                { t: 90, s: [-179] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 1, y: 0 },
                  t: 0,
                  s: [512, 384, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 15,
                  s: [512, 344, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 30,
                  s: [512, 384, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 50,
                  s: [512, 384, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 60,
                  s: [512, 344, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.167, y: 0.167 },
                  t: 75,
                  s: [512, 384, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 1, y: 0 },
                  t: 80,
                  s: [512, 384, 0],
                  to: null,
                  ti: null,
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 87,
                  s: [512, 344, 0],
                  to: null,
                  ti: null,
                },
                { t: 90, s: [512, 384, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [424, 424, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                  t: 80,
                  s: [100, 100, 100],
                },
                { t: 90, s: [0, 0, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          w: 848,
          h: 848,
          ip: 0,
          op: 120,
          st: 0,
          bm: 0,
          completed: true,
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "Layer 8 Outlines",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [427.74, 466.4, 0], ix: 2 },
                a: { a: 0, k: [180.05, 122.05, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [179.8, -114.6],
                            [179.8, 92.6],
                            [167, 121.8],
                            [-150.6, 121.8],
                            [-179.8, 109],
                            [-179.8, -111],
                            [-179.4, -118.2],
                            [-32.2, -7.8],
                            [13, 6.6000000000000005],
                            [177.8, -121.8],
                          ],
                          o: [
                            [179.8, -111],
                            [179.8, 108.6],
                            [150.6, 121.8],
                            [-166.6, 121.8],
                            [-179.8, 92.6],
                            [-179.8, -114.6],
                            [-177.8, -121.8],
                            [-13.400000000000002, 6.6000000000000005],
                            [31.8, -7.8],
                            [179, -118.6],
                          ],
                          v: [
                            [179.8, -111],
                            [179.8, 92.6],
                            [150.6, 121.8],
                            [-150.6, 121.8],
                            [-179.8, 92.6],
                            [-179.8, -111],
                            [-177.8, -121.8],
                            [-32.2, -7.8],
                            [31.8, -7.8],
                            [177.8, -121.8],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [180.05, 122.05], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "paper mask",
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [424, 424, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [90.598, 98.182, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [436, 306], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "st",
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [16, -67], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 0,
              nm: "paper",
              tt: 1,
              refId: "comp_2",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.834, y: 0.732 },
                      o: { x: 0.547, y: 0 },
                      t: 19,
                      s: [424, 309, 0],
                      to: null,
                      ti: null,
                    },
                    {
                      i: { x: 0.834, y: 0.26 },
                      o: { x: 0.224, y: 1 },
                      t: 38,
                      s: [424, 693.494, 0],
                      to: null,
                      ti: null,
                    },
                    {
                      i: { x: 0.384, y: 1 },
                      o: { x: 0.56, y: 0.756 },
                      t: 59,
                      s: [424, 635.494, 0],
                      to: null,
                      ti: null,
                    },
                    { t: 66, s: [424, 805, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [424, 424, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              w: 848,
              h: 848,
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Shape Layer 9",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 208.168, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Shape Layer 8",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 185.418, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Shape Layer 7",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 162.668, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 146,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Shape Layer 6",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 139.918, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 145,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Shape Layer 5",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 117.168, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Shape Layer 4",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 94.418, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 7,
                  ty: 4,
                  nm: "Shape Layer 3",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 71.668, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 146,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 8,
                  ty: 4,
                  nm: "Shape Layer 2",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 48.918, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 9,
                  ty: 4,
                  nm: "Shape Layer 1",
                  parent: 10,
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [130.969, 26.168, 0], ix: 2 },
                    a: { a: 0, k: [38.661, 24.727, 0], ix: 1 },
                    s: { a: 0, k: [59.491, 8.088, 100], ix: 6 },
                  },
                  ao: 0,
                  ef: [
                    {
                      ty: 21,
                      nm: "Fill",
                      np: 9,
                      mn: "ADBE Fill",
                      ix: 1,
                      en: 1,
                      ef: [
                        {
                          ty: 10,
                          nm: "Fill Mask",
                          mn: "ADBE Fill-0001",
                          ix: 1,
                          v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                          ty: 7,
                          nm: "All Masks",
                          mn: "ADBE Fill-0007",
                          ix: 2,
                          v: { a: 0, k: 0, ix: 2 },
                        },
                        {
                          ty: 2,
                          nm: "Color",
                          mn: "ADBE Fill-0002",
                          ix: 3,
                          v: { a: 0, k: [0.6588, 0.9255, 0.898, 1], ix: 3 },
                        },
                        {
                          ty: 7,
                          nm: "Invert",
                          mn: "ADBE Fill-0006",
                          ix: 4,
                          v: { a: 0, k: 0, ix: 4 },
                        },
                        {
                          ty: 0,
                          nm: "Horizontal Feather",
                          mn: "ADBE Fill-0003",
                          ix: 5,
                          v: { a: 0, k: 0, ix: 5 },
                        },
                        {
                          ty: 0,
                          nm: "Vertical Feather",
                          mn: "ADBE Fill-0004",
                          ix: 6,
                          v: { a: 0, k: 0, ix: 6 },
                        },
                        {
                          ty: 0,
                          nm: "Opacity",
                          mn: "ADBE Fill-0005",
                          ix: 7,
                          v: { a: 0, k: 1, ix: 7 },
                        },
                      ],
                    },
                  ],
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ty: "rc",
                          d: 1,
                          s: { a: 0, k: [356, 100], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          r: { a: 0, k: 0, ix: 4 },
                          nm: "Rectangle Path 1",
                          mn: "ADBE Vector Shape - Rect",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [38, 18], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Rectangle 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 146,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
                {
                  ddd: 0,
                  ind: 10,
                  ty: 4,
                  nm: "Paper",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [427.742, 206.454, 0], ix: 2 },
                    a: { a: 0, k: [128.211, 113.24, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [118.86500000000001, 112.99],
                                [-107.643, 112.99],
                                [-127.96, 103.894],
                                [-127.96, -92.673],
                                [-118.864, -112.99],
                                [107.644, -112.99],
                                [127.96, -103.894],
                                [127.96, 92.673],
                              ],
                              o: [
                                [107.644, 112.99],
                                [-118.864, 112.99],
                                [-127.96, 92.673],
                                [-127.96, -103.894],
                                [-107.643, -112.99],
                                [118.86500000000001, -112.99],
                                [127.96, -92.673],
                                [127.96, 103.894],
                              ],
                              v: [
                                [107.644, 112.99],
                                [-107.643, 112.99],
                                [-127.96, 92.673],
                                [-127.96, -92.673],
                                [-107.643, -112.99],
                                [107.644, -112.99],
                                [127.96, -92.673],
                                [127.96, 92.673],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [0.9145, 0.9855, 0.9784, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                          _render: true,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [128.21, 113.24], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                          _render: true,
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                      _render: true,
                    },
                  ],
                  ip: 0,
                  op: 147,
                  st: 0,
                  bm: 0,
                  completed: true,
                },
              ],
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "Cap",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [427.865, 345.49, 0], ix: 2 },
                a: { a: 0, k: [177.945, 128.36, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 1, 0.333], y: [0, 0, 0] },
                      t: 6,
                      s: [100, -101, 100],
                    },
                    {
                      i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 24,
                      s: [100, 100, 100],
                    },
                    {
                      i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 47,
                      s: [100, 100, 100],
                    },
                    {
                      i: { x: [0.667, 0.238, 0.667], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 53,
                      s: [100, 113, 100],
                    },
                    {
                      i: { x: [0.667, 0, 0.667], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 59,
                      s: [100, 100, 100],
                    },
                    { t: 75, s: [100, -101, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [177.57, 64.11],
                            [-177.57, 64.11],
                            [-32.2, -49.71],
                            [13, -64.11],
                          ],
                          o: [
                            [177.57, 64.11],
                            [-177.57, 64.11],
                            [-13.400000000000002, -64.11],
                            [31.8, -49.71],
                          ],
                          v: [
                            [177.57, 64.11],
                            [-177.57, 64.11],
                            [-32.2, -49.71],
                            [31.8, -49.71],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.2993, 0.4395, 0.5207, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [177.82, 64.36], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 147,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "Layer 1 Outlines",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [428.74, 444.532, 0], ix: 2 },
                a: { a: 0, k: [179.05, 100.182, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [178.8, 99.932],
                            [-178.8, 99.932],
                            [-178.8, -99.932],
                            [176.802, -99.75],
                          ],
                          o: [
                            [178.8, 99.932],
                            [-178.8, 99.932],
                            [-178.8, -99.932],
                            [176.802, -99.75],
                          ],
                          v: [
                            [178.8, 99.932],
                            [-178.8, 99.932],
                            [-178.8, -99.932],
                            [176.802, -99.75],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.2695, 0.3715, 0.4305, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [179.05, 100.182], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 147,
              st: 0,
              bm: 0,
              completed: true,
            },
          ],
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: "check (1)",
          refId: "comp_3",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [512, 384, 0], ix: 2 },
            a: { a: 0, k: [452.5, 452.5, 0], ix: 1 },
            s: { a: 0, k: [40, 40, 100], ix: 6 },
          },
          ao: 0,
          w: 905,
          h: 905,
          ip: 78,
          op: 198,
          st: 78,
          bm: 0,
          completed: true,
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "Layer 1 Outlines 2",
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.838, y: 0.896 },
                      o: { x: 0.951, y: 0 },
                      t: 0,
                      s: [583.5, 455.5, 0],
                      to: [0, -6.373, 0],
                      ti: [-10.44, -24.391, 0],
                    },
                    {
                      i: { x: 0.543, y: 1 },
                      o: { x: 0.233, y: 0.039 },
                      t: 15,
                      s: [458.463, 464.899, 0],
                      to: [0.482, 1.125, 0],
                      ti: [-0.581, -1.507, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [460.5, 396.5, 0],
                      to: [13.167, 34.167, 0],
                      ti: [1.167, -4.167, 0],
                    },
                    { t: 25, s: [453.5, 421.5, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [256, 256, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      t: 15,
                      s: [110, 110, 100],
                    },
                    { t: 24, s: [100, 100, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [141.164, -256],
                            [-256, -141.164],
                            [-141.164, 256],
                            [256, 141.164],
                          ],
                          o: [
                            [-141.164, -256],
                            [-256, 141.164],
                            [141.164, 256],
                            [256, -141.164],
                          ],
                          v: [
                            [0, -256],
                            [-256, 0],
                            [0, 256],
                            [256, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.2784, 0.8353, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [256, 256], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "Check Outlines",
              parent: 3,
              tt: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 1, y: 0 },
                      t: 0,
                      s: [424, 424, 0],
                      to: [-21.167, 25.667, 0],
                      ti: [25.667, 26.833, 0],
                    },
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 11,
                      s: [297, 578, 0],
                      to: [-25.667, -26.833, 0],
                      ti: [4.5, 52.5, 0],
                    },
                    { t: 25, s: [270, 263, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [266.667, 255.998, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      t: 15,
                      s: [120, 120, 100],
                    },
                    { t: 24, s: [100, 100, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [127.424, -61.549],
                            [-19.584, 85.459],
                            [-29.205, 91.713],
                            [-45.587999999999994, 89.619],
                            [-119.08, 16.127],
                            [-127.42399999999999, -5.697000000000001],
                            [-97.25999999999999, -22.381],
                            [-34.666, 40.213],
                            [88.92, -83.369],
                            [110.74000000000001, -91.713],
                          ],
                          o: [
                            [119.084, -53.205],
                            [-23.744, 89.619],
                            [-40.126999999999995, 91.713],
                            [-49.748, 85.459],
                            [-127.42399999999999, 7.786999999999999],
                            [-110.74, -22.381],
                            [-88.916, -14.037],
                            [-34.666, 40.213],
                            [97.26, -91.713],
                            [127.424, -75.029],
                          ],
                          v: [
                            [119.084, -53.205],
                            [-19.584, 85.459],
                            [-34.666, 91.713],
                            [-49.748, 85.459],
                            [-119.08, 16.127],
                            [-119.08, -14.037],
                            [-88.916, -14.037],
                            [-34.666, 40.213],
                            [88.92, -83.369],
                            [119.084, -83.369],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.98, 0.98, 0.98, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [266.666, 254.955], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "Layer 1 Outlines",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.838, y: 0.896 },
                      o: { x: 0.951, y: 0 },
                      t: 0,
                      s: [583.5, 455.5, 0],
                      to: [0, -6.373, 0],
                      ti: [-10.44, -24.391, 0],
                    },
                    {
                      i: { x: 0.543, y: 1 },
                      o: { x: 0.233, y: 0.039 },
                      t: 15,
                      s: [458.463, 464.899, 0],
                      to: [0.482, 1.125, 0],
                      ti: [-0.581, -1.507, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [460.5, 396.5, 0],
                      to: [13.167, 34.167, 0],
                      ti: [1.167, -4.167, 0],
                    },
                    { t: 25, s: [453.5, 421.5, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [256, 256, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      t: 15,
                      s: [110, 110, 100],
                    },
                    { t: 24, s: [100, 100, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [141.164, -256],
                            [-256, -141.164],
                            [-141.164, 256],
                            [256, 141.164],
                          ],
                          o: [
                            [-141.164, -256],
                            [-256, 141.164],
                            [141.164, 256],
                            [256, -141.164],
                          ],
                          v: [
                            [0, -256],
                            [-256, 0],
                            [0, 256],
                            [256, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [256, 256], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "Layer 1 Outlines 3",
              sr: 1,
              ks: {
                o: { a: 0, k: 20, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.838, y: 0.896 },
                      o: { x: 0.951, y: 0 },
                      t: 0,
                      s: [583.5, 455.5, 0],
                      to: [0, -6.373, 0],
                      ti: [-10.44, -24.391, 0],
                    },
                    {
                      i: { x: 0.543, y: 1 },
                      o: { x: 0.233, y: 0.039 },
                      t: 15,
                      s: [458.463, 464.899, 0],
                      to: [0.482, 1.125, 0],
                      ti: [-0.581, -1.507, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [460.5, 396.5, 0],
                      to: [13.167, 34.167, 0],
                      ti: [1.167, -4.167, 0],
                    },
                    { t: 25, s: [453.5, 421.5, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [256, 256, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      t: 15,
                      s: [110, 110, 100],
                    },
                    {
                      i: { x: [0, 0, 0.833], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 21,
                      s: [100, 100, 100],
                    },
                    { t: 26, s: [130, 130, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [141.164, -256],
                            [-256, -141.164],
                            [-141.164, 256],
                            [256, 141.164],
                          ],
                          o: [
                            [-141.164, -256],
                            [-256, 141.164],
                            [141.164, 256],
                            [256, -141.164],
                          ],
                          v: [
                            [0, -256],
                            [-256, 0],
                            [0, 256],
                            [256, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [256, 256], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "Layer 1 Outlines 4",
              sr: 1,
              ks: {
                o: { a: 0, k: 10, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.838, y: 0.896 },
                      o: { x: 0.951, y: 0 },
                      t: 0,
                      s: [583.5, 455.5, 0],
                      to: [0, -6.373, 0],
                      ti: [-10.44, -24.391, 0],
                    },
                    {
                      i: { x: 0.543, y: 1 },
                      o: { x: 0.233, y: 0.039 },
                      t: 15,
                      s: [458.463, 464.899, 0],
                      to: [0.482, 1.125, 0],
                      ti: [-0.581, -1.507, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [460.5, 396.5, 0],
                      to: [13.167, 34.167, 0],
                      ti: [1.167, -4.167, 0],
                    },
                    { t: 25, s: [453.5, 421.5, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [256, 256, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [1, 1, 0.333], y: [0, 0, 0] },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      t: 15,
                      s: [110, 110, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 21,
                      s: [100, 100, 100],
                    },
                    {
                      i: { x: [0, 0, 0.833], y: [1, 1, 1] },
                      o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                      t: 26,
                      s: [100, 100, 100],
                    },
                    { t: 32, s: [160, 160, 100] },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [141.164, -256],
                            [-256, -141.164],
                            [-141.164, 256],
                            [256, 141.164],
                          ],
                          o: [
                            [-141.164, -256],
                            [-256, 141.164],
                            [141.164, 256],
                            [256, -141.164],
                          ],
                          v: [
                            [0, -256],
                            [-256, 0],
                            [0, 256],
                            [256, 0],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.4235, 0.7725, 0.9686, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                      _render: true,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [256, 256], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                      _render: true,
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                  _render: true,
                },
              ],
              ip: 0,
              op: 120,
              st: 0,
              bm: 0,
              completed: true,
            },
          ],
        },
      ],
    },
  ],
  markers: [],
  __complete: true,
};
