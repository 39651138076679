import React, { useEffect } from 'react'
import TopNav from '../bars/TopNav';
import Footer from '../bars/Footer';
import ticketingSystemImage from '../image/ticketingsystem.png'
import conversation from '../image/conver.png'
import workflow from '../image/workflow.png'
import ease from '../image/ease.png'
import report from '../image/report.png'
import Avaya from '../image/avayaText.png'
import Ticket from '../image/tickImage.png'
import Knowledge from '../image/Knowledge.png'
import Admin from '../image/admin.png'
import Agent from '../image/agent.png'

function TicketingSystem() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <TopNav />
            <div className='mb-20 lg:mt-[70px] mt-16'>
                <div className='h-[358px] bg-cover bg-center flex flex-col items-center justify-center text-center' style={{ backgroundImage: `url(${ticketingSystemImage})` }}>
                    <div className='top_section'>
                        <div className='text-[#FFF] text-[40px] lg:text-[64px] font-semibold'>Ticketing System</div>
                        <div className='text-[#FFF] text-[20px] lg:text-[40px] font-semibold'>Streamline Your operations & Enhance Customer Service</div>
                    </div>
                </div>
                <div className='top_section mt-10 lg:mt-[73px] text-center'>
                    <div className='mx-0 lg:mx-16 font-normal text-[#000] lg:text-xl text-sm leading-[28px] tracking-[-0.14] lg:leading-[36px] lg:tracking-[-0.2px]'>
                        Streamline your support operations, empower agents, and enhance customer service with our comprehensive ticketing system. This system enables agents to easily create tickets and capture essential information while on calls. It streamlines case management for customer service desks, bridging the gap between back-office tasks and customer interactions. It also triggers immediate real-time notifications or directly escalates issues to the appropriate teams.
                    </div>
                    <div className='mt-[73px] w-fit mx-auto avaya text-[#828282] text-sm lg:text-[22px] font-normal'>Unlock seamless experience by integrating our
                        <br /><span className='flex sm:flex-row flex-col space-x-0 sm:space-x-3 justify-center items-center pt-1'>Ticketing System on top of the <img className='lg:w-[350.602px] lg:h-[30px] w-[168.001px] h-[14.375px] ml-2 mt-1' src={Avaya} alt='avaya' /></span>
                    </div>
                    <div className='lg:mt-[120px] mt-10  grid grid-flow-row md:grid-cols-2  grid-cols-1 md:gap-x-6 gap-y-6'>
                        <div className='box-1 flex lg:flex-row flex-col min-h-[300px]'>
                            <img className='w-[64px] h-[64px] lg:w-20 lg:h-20' src={conversation} alt='conversation' />
                            <div className='flex flex-col ml-0 lg:ml-12'>
                                <div className='boxH text-left mt-2'>Enhanced Customer Conversations</div>
                                <div className='boxList1 text-left mt-5 '>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Prioritize customer requests</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Create, delegate & escalate tickets</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Bring context to every conversation</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Know your customer</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Repository of knowledge</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box-2 flex lg:flex-row flex-col min-h-[300px]'>
                            <img className='w-[64px] h-[64px] lg:w-20 lg:h-20' src={workflow} alt='workflow' />
                            <div className='flex flex-col ml-0 lg:ml-12'>
                                <div className='boxH text-left mt-2'>Workflows</div>
                                <div className='boxList1 text-left mt-5'>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Optimized workflows</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Push notifications</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Delegation process</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box-3 flex lg:flex-row flex-col min-h-[300px]'>
                            <img className='w-[64px] h-[64px] lg:w-20 lg:h-20' src={ease} alt='ease' />
                            <div className='flex flex-col ml-0 lg:ml-12'>
                                <div className='boxH text-left mt-2'>Ease of Integration</div>
                                <div className='boxList1 text-left mt-5'>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>UCaaS and CCaaS services</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div> Business apps</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Avaya platform</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Social Media channels & SMS</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box-4 flex lg:flex-row flex-col min-h-[300px]'>
                            <img className='w-[64px] h-[64px] lg:w-20 lg:h-20' src={report} alt='report' />
                            <div className='flex flex-col ml-0 lg:ml-12'><div className='boxH text-left mt-2'>Reporting</div>
                                <div className='boxList1 text-left mt-5'>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Interactive dashboard</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Mini CRM and built-in tracking</div>
                                    </div>
                                    <div className='flex space-x-2 mt-1 items-start'>
                                        <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                        <div>Analytics reporting</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lg:mt-[96px] mt-8 text-center text-[24px] lg:text-[44px] font-semibold'>Key Benefits</div>
                <div className='mt-6 lg:mt-[56px] bg-[#FAFAFA]'>
                    <div className='top_section'>
                        <div className='flex lg:flex-row flex-col lg:space-x-6 space-x-0'>
                            <div className='smartCards w-full lg:w-1/2'>
                                <div className='smartcardP'><span className='smartcardH'>More Control and Workflow Automation:</span> Provides organizations with greater control over their support processes. It automates repetitive tasks, ensuring that tickets are routed to the right agents, and specific actions are taken based on predefined rules, resulting in increased efficiency and reduced manual effort.</div>
                            </div>
                            <div className='smartCards w-full lg:w-1/2'>
                                <div className='smartcardP'><span className='smartcardH'>Centralized Data Management:</span> Ensures all customer interactions and case details are easily accessible from a single platform. This simplifies information retrieval, enhances data accuracy, and allows for better reporting and analysis.</div>
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col lg:space-x-6 space-x-0'>
                            <div className='smartCards w-full lg:w-1/2'>
                                <div className='smartcardP'><span className='smartcardH'>Enhances Customer Service: </span> Enable agents to access customer information quickly, leading to more personalized and efficient interactions. This, in turn, boosts customer satisfaction and loyalty.</div>
                            </div>
                            <div className='smartCards w-full lg:w-1/2'>
                                <div className='smartcardP'><span className='smartcardH'>Statistical Capabilities:</span>   Equipped with advanced reporting and analytics features, our ticketing system generates insightful statistics and reports, providing valuable insights into support team performance, customer trends, and areas that need improvement, ultimately aiding in informed decision-making.</div>
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col lg:space-x-6 space-x-0'>
                            <div className='smartCards w-full lg:w-1/2'>
                                <div className='smartcardP'><span className='smartcardH'>Flexible Solution with Mini CRM: </span> Our ticketing system includes mini CRM features that not only handle customer issues but also maintain a record of customer interactions, preferences, and history. This added flexibility helps in building stronger and lasting customer relationships.</div>
                            </div>
                            <div className='smartCards w-full lg:w-1/2'>
                                <div className='smartcardP'><span className='smartcardH'>Trigger Real-time Notiﬁcations:</span> Instantly alert agents or supervisors when new tickets are created, ensuring that issues are addressed promptly, thereby improving response times and customer satisfaction.</div>
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col lg:space-x-6 space-x-0'>
                            <div className='smartCards w-full lg:w-1/2'>
                                <div className='smartcardP'><span className='smartcardH'>Alert supervisor with unhappy customers:</span> Automatically flags responses from unhappy customers, allowing supervisors and support teams to intervene promptly. This enables you to resolve issues and prevent potential customer churn.</div>
                            </div>
                            <div className='smartCards w-full lg:w-1/2'>
                                <div className='smartcardP'><span className='smartcardH'>Determine your CX Improvement Priorities:</span>Determine your CX Improvement Priorities: By continuously monitoring feedback trends, smart surveys help you track changes in customer sentiment. This data allows you to prioritize areas for improvement and allocate resources accordingly, ultimately enhancing the customer experience.</div>
                            </div>
                        </div>
                        <div className='flex items-center justify-center'>
                            <div className='smartCards w-full lg:w-1/2'>
                                <div className='smartcardP text-justify'><span className='smartcardH'>Direct Escalation to the Concerned Team: </span> automatic escalation of issues to the relevant teams or individuals based on predefined criteria. This ensures that critical matters are swiftly brought to the attention of the right people, avoiding delays and ensuring efficient issue resolution.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='top_section lg:mt-[96px] mt-8'>
                    <img src={Ticket} alt='ticket' />
                </div>
                <div className='top_section lg:mt-[96px] mt-8'>
                    <div className='flex lg:flex-row flex-col items-center space-x-0 lg:space-x-6'>
                        <div className='flex-col w-full lg:w-1/2 text-center lg:text-left'>
                            <div className='enhance'>Knowledge Base</div>
                            <div className='enhanceP mt-6 lg:mt-10'>A repository of knowledge that helps advisors quickly find the information they seek to drive a smooth and swift interaction, thus enhancing the customers' experience with a satisfied resolution.</div>
                        </div>
                        <img className='w-full sm:w-[421px] lg:w-1/2 mt-10 lg:mt-0' src={Knowledge} alt='knowledge' />
                    </div>
                </div>
                <div className='top_section lg:mt-[96px] mt-8'>
                    <div className='flex-col space-y-6'>
                        <div className='w-full grid grid-flow-row lg:grid-cols-3 grid-cols-1 gap-6'>
                            <div className='flex items-center justify-center lg:p-8 p-4 text-center lg:text-xl text-sm bg-[#CAE8FF] rounded-xl'>
                                <div>Agent-Friendly Interface</div>
                            </div>
                            <div className='flex items-center justify-center lg:p-8 p-4 text-center lg:text-xl text-sm bg-[#CAFFD6] rounded-xl'>
                                <div>Quick & Simple Search Platform</div>
                            </div>
                            <div className='flex items-center justify-center lg:p-8 p-4 text-center lg:text-xl text-sm bg-[#CAE8FF] rounded-xl'>
                                <div>Enhance Agent Performance</div>
                            </div>

                        </div>
                        <div className="mt-6 lg:mx-20 grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-6">
                            <div className='flex items-center justify-center lg:p-8 p-4 text-center lg:text-xl text-sm bg-[#CAF8FF] rounded-xl'>
                                <div>Prompt Response to customers Queries</div>
                            </div>
                            <div className='flex items-center justify-center lg:p-8 p-4 text-center lg:text-xl text-sm bg-[#CAD8FF] rounded-xl'>
                                <div>Quality customer experience</div>
                            </div>
                        </div>
                        <div className="mx-auto grid grid-flow-row grid-cols-1 gap-6">
                            <div className='lg:mx-96 flex items-center justify-center lg:p-8 p-4 text-center lg:text-xl text-sm bg-[#CAFFE6] rounded-xl'>
                                <div>Customer Satisfaction</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='top_section lg:mt-[96px] mt-8'>
                    <div className='mx-auto lg:py-7 lg:px-[56px] px-8 py-6 w-fit rounded-2xl border border-[#000] text-center'>
                        <div className='text-[#434343] text-base lg:text-[22px] font-semibold'>Two Interfaces:<br />Admin Interface  I  Agent Interface</div>
                    </div>
                </div>
                <div className='top_section lg:mt-[56px] mt-6'>
                    <div className='lg:p-8 p-5 flex lg:flex-row flex-col items-center lg:space-x-8 space-x-0 space-y-6 lg:space-y-0 rounded-2xl bg-[#F8F1FF] border border-[#F1F2FF]'>
                        <div className='lg:w-1/2 w-full flex-col space-y-8'>
                            <div className='boxH'>Admin Privileges</div>
                            <div className='boxList1 lg:w-[80%] text-left'>
                                <div className='flex space-x-2 mt-1 items-start'>
                                    <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                    <div>Create new knowledge base, add new categories and groups within it </div>
                                </div>
                                <div className='flex space-x-2 mt-1 items-start'>
                                    <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                    <div>Edit the category, group or description</div>
                                </div>
                                <div className='flex space-x-2 mt-1 items-start'>
                                    <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                    <div>Upload the related docs in the pdf, csv, txt, image formats</div>
                                </div>
                            </div>
                        </div>
                        <img className='lg:w-1/2 w-full sm:h-[384px]' src={Admin} alt='admin' />
                    </div>
                </div>
                <div className='top_section lg:mt-[47px] mt-6'>
                    <div className='lg:p-8 p-5 flex lg:flex-row flex-col items-center lg:space-x-8 space-x-0 space-y-6 lg:space-y-0 rounded-2xl bg-[#F8F1FF] border border-[#F1F2FF]'>
                        <div className='lg:w-1/2 w-full flex-col space-y-8'>
                            <div className='boxH'>Agent Privileges</div>
                            <div className='boxList1 lg:w-[80%] text-left'>
                                <div className='flex space-x-2 mt-1 items-start'>
                                    <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                    <div>Readily available answers related to customer queries without the need for scattered searches </div>
                                </div>
                                <div className='flex space-x-2 mt-1 items-start'>
                                    <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                    <div>Retrieve, download and share the relevant docs with the customer in just a few clicks</div>
                                </div>
                                <div className='flex space-x-2 mt-1 items-start'>
                                    <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                    <div>Provide comments for necessary updates or modifications</div>
                                </div>
                                <div className='flex space-x-2 mt-1 items-start'>
                                    <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-4'></div>
                                    <div>Search by category and with keyword</div>
                                </div>
                            </div>
                        </div>
                        <img className='lg:w-1/2 w-full sm:h-[384px]' src={Agent} alt='admin' />
                    </div>
                </div>
                <div className='top_section mt-8 lg:mt-[56px]'>
                    <div className="flex justify-center">
                        <a href='#/contact' className='rounded text-white text-base font-semibold px-6 py-4 bg-[#006FFF] hover:bg-[#224ed4]'>Request a demo</a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TicketingSystem

