import CloudImg from "../pages/blog/images/cloud.png";
export const currentYear = new Date().getFullYear();
export const contactUsAPI =
  "https://1vdi4lgmfa.execute-api.us-east-1.amazonaws.com/insert_details";
export const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#006fff",
      color: "#fff",
    },
  },
  cells: {
    style: {
      backgroundColor: "#fff",
      color: "#000",
    },
  },
  cells: {
    style: {
      backgroundColor: "#fff",
      color: "#000",
    },
  },
  rows: {
    style: { paddingTop: "10px", paddingBottom: "10px" },
  },
};

export const faqContent = [
  {
    question: "What is Inaipi DCC?",
    answer:
      "Inaipi Digital Contact Center is an omnichannel platform empowering agents to deliver seamless customer experience across all touchpoints. This innovative platform leverages AI-powered solutions to revolutionize the customer journey.",
    divName: "inaipiDcc",
  },
  {
    question: "What is Cloud Contact Center ?",
    answer:
      "A cloud contact center is a customer service and support solution that is hosted and operated in the cloud, allowing businesses to manage and handle customer interactions, such as phone calls, emails, chats, and more, from anywhere with internet connectivity. It offers flexibility, scalability, and cost-effectiveness compared to traditional on-premises contact center systems.",
    divName: "contactCenter",
  },
  {
    question: "Cloud Contact Center Benefits ?",
    answer:
      "Cost-efficiency, scalability, flexibility, remote accessibility, and the ability to access advanced features and analytics for enhanced customer service and support.",
    divName: "benefits",
  },
  {
    question: "What is Digital Contact Center ?",
    answer:
      "Customer service and support platform that uses digital channels like email, chat, social media, and messaging apps to engage with customers, providing a more versatile and convenient means of communication compared to traditional phone-based call centers.",
    divName: "digital",
  },
  {
    question: "Benefits of Digital Channels ?",
    answer:
      "Faster communication, wider reach, cost-efficiency, convenience, and the ability to track and analyze customer interactions for improved service.",
    divName: "benefitChannel",
  },
  {
    question: "Benefits of Co-browsing ?",
    answer:
      "Co-browsing is an invaluable tool for businesses and customer support. It allows for real-time visual collaboration between agents and customers, enhancing the overall experience.",
    divName: "cobrwosing",
  },
  {
    question: "How Do I Migrate My Existing Contact Center To The Cloud?",
    answer:
      "The migration process varies depending on your current setup and requirements. Generally, it involves assessing your needs, selecting a suitable cloud contact center provider, data migration, and training for your team. We offer you guidance and support throughout the migration process.",
    divName: "migrate",
  },
  {
    question:
      "Can I Integrate A Cloud Contact Center With My Existing CRM Or Other Business Systems?",
    answer:
      "Yes, our solutions offer integration options with popular CRM platforms and other business applications to ensure a seamless flow of customer data and interaction history across systems.",
    divName: "integrate",
  },
];

export const blog_cards = [
  {
    title:
      "Your On-Premise, Supercharged in the Cloud: An Effortless Transition Journey",
    image: CloudImg,
    // date: "Sep 19, 2024",
    link: "../blogs/cloud-migration",
  } 
];

export const cloud_migration_feature = [
  "No need for infrastructure upgrades",
  "Maximize the possibilities of the platforms you already have",
  "Add best-in-cloud technologies onto your current contact center platform",
  "Reduced risk of business disruption",
  "Preserve your existing investments",
  "Improve the EX for a Better CX",
  
  "All Agents and Supervisors benefit from a modern desktop experience",
  "Stay in step with industry trends toward cloud contact center solutions",
  "Experience the features of the cloud contact center",
  "Supports all customer touchpoints – voice, chat, email, digital channels",
  "Unify voice, video, chat, and messaging",
  "Easier way to work remotely",
  "Seamless integration",
  "Customizable to unique requirements, no matter the industry",
  "Drive new and better business outcomes and experiences",
];


export const cloud_migration_smooth_transition = [
  {
    head:"Comprehensive Cloud Assessment: ",
    para:"We conduct a detailed analysis of your existing infrastructure, applications, and data to determine the best cloud architecture and migration strategy. We then create a customized roadmap for a smooth transition."
  },{
    head:"Strategic Planning and Execution: ",
    para:"We collaborate closely with your organization to develop a migration plan that aligns with your business goals. Our meticulous planning minimizes risks, streamlines the process, and optimizes resource allocation."
  },{
    head:"Seamless Data Migration: ",
    para:"Our experts use industry-leading tools and methods for secure data migration. We handle data extraction, transformation, and loading, ensuring minimal downtime and operational impact."
  },{
    head:"Robust Cloud Architecture: ",
    para:"We design a scalable, resilient, and cost-effective cloud infrastructure tailored to your needs. Leveraging advanced cloud services, we ensure high availability, improved performance, and enhanced security."
  },{
    head:"Ongoing Support and Optimization: ",
    para:"Beyond migration, we provide continuous support and monitoring. Regular performance assessments, bottleneck identification, and optimization strategies ensure efficiency and cost-effectiveness. We stay updated on the latest cloud technologies and trends to provide strategic recommendations and future-proof your cloud infrastructure."
  } 
]
 
export const social_media_links = [
  {
    name:"linkedin",
    link:"https://www.linkedin.com/company/inaipi-app"
  },
  {
    name:"facebook",
    link:"https://www.facebook.com/Inaipiapp"
  },
  {
    name:"instagram",
    link:"https://www.instagram.com/inaipi_app"
  },
  {
    name:"twitter",
    link:"https://twitter.com/inaipiapp"
  },
 
  {
    name:"youtube",
    link:"https://www.youtube.com/@inaipi6331"
  }
]
