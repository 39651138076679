import React from "react";
import ArrowIcon from "../../../svg/arrow.svg"
import { useNavigate } from "react-router-dom";

function BlogBackBtn() {
    const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(-1)}
      className="arrow-btn shrink-0 mt-2 w-8 h-8 rounded-full opacity-65 hover:opacity-100 bg-white flex items-center justify-center"
    >
      <img src={ArrowIcon} alt="" />
    </button>
  );
}

export default BlogBackBtn;
