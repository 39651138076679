import React from "react";
import SuccessImg from "../image/success.png";
 import SuccessAnalytics from "./SuccessAnalytics";
function Success({ closeBtn }) {
  const close = () => {
    closeBtn();
  };
  return (
    <div>  
          <SuccessAnalytics />
    
    <div className="flex justify-center w-full">
      <div className="relative w-8/12 h-8/12">
        <button onClick={close} className="absolute top-6 right-6">
          X
        </button>
        <img
          src={SuccessImg}
          className=""
          alt="Thank you for connecting with us!!"
        />
      </div>
    </div>
    </div>
  );
}

export default Success;
