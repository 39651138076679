import React from "react";
import Tickmark from "../../../image/tick-mark.png";
function List(props) {
  const { data } = props;
  return (
    <ul className="sign-up-lists">
      {data?.map((item, i) => {
        return (
          <li data-aos="fade-right" className="flex items-center gap-3 my-3" key={i}>
            <img className="w-[14px]" src={Tickmark} alt="" />
            {item}
          </li>
        );
      })}
    </ul>
  );
}

export default List;
