import React, { useEffect, useState } from "react";
import Footer from "../../../bars/Footer";
import TopNav from "../../../bars/TopNav";
import { sign_up_animation } from "../../../lottie/animations";
import Lottie from "lottie-react";
import { useLocation, useNavigate } from "react-router-dom";

function SuccessSignUp() {
  const [messageFlag, setMessageFlag] = useState(false);
   const navigate = useNavigate();
  const [email, setEmail] = useState(
    localStorage.getItem("registered_sign_up_emial")
  );
  useEffect(() => {
    if (email) {
      setTimeout(() => {
        setMessageFlag(true);
      }, 3500);
      localStorage.removeItem("registered_sign_up_emial");
    } else {
      navigate("../sign-up")
    }
  }, []);

  return (
    <div>
      <TopNav />

      <div className="h-[80vh] w-full flex flex-col md:mt-24 mt-36 items-center">
        <Lottie
          className="w-[250px]"
          animationData={sign_up_animation}
          loop={false}
        />
        {messageFlag && (
          <div className="mt-5 success-card absolute md:top-[58%] top-[50%] w-[200px]">
            <h2> Congratulations! 🎉 Your registration was successful.</h2>

            <p className="mt-4"> 
            A confirmation email has been sent to  
              <span className="text-blue-500 ml-2">{email}</span>
            </p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default SuccessSignUp;
