import React from "react";
import BackButton from "../../products/components/BackButton";
import BlogBackBtn from "./BlogBackBtn";

function BlogHead(props) {
  const { coverPic, heading } = props;
  return (
    <div
      className="blog-head h-[240px] lg:mt-[74px] mt-[68px] w-full relative flex items-center "
      style={{ background: `url(${coverPic})`, backgroundPosition: "center", backgroundSize:"cover", backgroundRepeat:"no-repeat" }}
    >
      <div className="absolute h-full w-full top-0 left-0 bg-black opacity-40"></div>
      <div className="top_section flex gap-3  relative z-10 ">
        <BlogBackBtn/>
        <h2>{heading}</h2>
      </div>
    </div>
  );
}

export default BlogHead;
