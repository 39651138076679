import axios from "axios";
import { contactUsAPI } from "../contsants/constant";
import { sign_up_base_url } from "../contsants/baseUrl";

export const sent_contact_us = (body) => {

     let config = {
      method: "post",
      url: contactUsAPI,
      headers: {
        "Content-Type": "application/json",
      },
      data : body,
    };

    return axios
      .request(config)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

  export const sign_up_ = (body) => {

    let config = {
     method: "post",
     url: `${sign_up_base_url}/trailUserCreate`,
     headers: {
       "Content-Type": "application/json",
     },
     data : body,
   };

   return axios
     .request(config)
     .then((response) => response.data)
     .catch((error) => {
       console.error(error);
       throw error;
     });
 };  
  