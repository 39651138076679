import React, { useState, useEffect } from "react";
import TopNav from "../bars/TopNav";
import Footer from "../bars/Footer";
import plus from "../svg/Plus.svg";
import minus from "../svg/minus.svg";
import { faqContent } from "../contsants/constant";

function Faq() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [divStates, setDivStates] = useState({});

  const toggleDiv = (divId) => {
    setDivStates((prevState) => ({
      ...prevState,
      [divId]: !prevState[divId],
    }));
  };

  return (
    <div>
      <TopNav />
      <div className="top_section mt-[120px] mb-[73px]">
        <h2 className="text-center lg:font-semibold font-medium">FAQs</h2>
        <p className="w-full pt-8 md:w-[60%] text-center text-[#797979] mx-auto">
          Have questions? We've got answers! Explore the frequently asked
          questions below to learn more about how Inaipi can transform your
          customer service experience.
        </p>
        <div className="flex flex-col space-y-10 mt-[56px]">
          {faqContent.map((item, i) => {
            return (
              <div className="flex flex-col">
                <div
                  onClick={() => toggleDiv(item?.divName)}
                  className="flex justify-between items-center cursor-pointer w-full pb-5"
                  style={{ borderBottom: "1px solid var(--para, #797979)" }}
                >
                  <div className="faq">{item?.question}</div>
                  {divStates[item?.divName] ? (
                    <img src={minus} className="plus" alt="plus" />
                  ) : (
                    <img src={plus} className="plus" alt="plus" />
                  )}
                </div>
                <div
                  className={`toggle-container ${
                    divStates[item?.divName] ? "visible" : "hidden"
                  }`}
                >
                  <div className="content">
                    <p className="p-5 leading-[35px]">{item?.answer}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
