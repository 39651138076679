import React, { useState, useEffect, useRef } from "react";
import Topbar from "../../bars/TopNav.js";
import ImgContact from "../../image/signup.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Footer from "../../bars/Footer.js";
import GoogleAnalytics from ".././GoogleAnalytics";
import { useForm, Controller} from "react-hook-form";
import { signupFormSchema } from "../../validations/signup.js";
import { yupResolver } from "@hookform/resolvers/yup";
import { sign_up_ } from "../../actions/contact.js";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import List from "./components/List.js";
import { sign_up_features } from "../products/constants/arrays.js";
import { useNavigate } from "react-router-dom";
function SignUp() {
   
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors},
  } = useForm({
    defaultValues: {
      first_name: null,
      last_name: null,
      email: "",
      company :"",
      country_code:"91",
      phone_no:"",
      // interested_product: "",
    },
    resolver: yupResolver(signupFormSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 

  const formSubmit = async (data) => {

    var body = {
      firstName: data?.first_name,
      lastName: data?.last_name,
      companyName: data?.company,
      mobileNumber: data?.country_code + data?.phone_no,
      email: data?.email,
     };
     
     setLoading(true)
    sign_up_(body)
      .then((res) => {
       console.log(res)
       setLoading(false)
       if(res?.status){
        if(res?.data?.status ==="OK"){
          // toast.success(res?.data?.message, {position: "top-right"}, {
          //   theme: "dark"
          // });
          reset()
          localStorage.setItem('registered_sign_up_emial',data?.email)
          navigate(`../success`);
      
        }else{
          console.log("object")
          toast.warning(res?.data?.message, {position: "top-right"}, {
            theme: "dark"
          });
        }
       }
       else{
        toast.warning(res?.data ?  res?.data?.message : "Something went wrong", {position: "top-right"}, {
          theme: "dark"
        });
       }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      });

  };

 
 

  return (
    <div>
      <Topbar />
      <GoogleAnalytics />
      <div className="top_section mt-[100px] mb-[40px]">
          <div className="flex lg:flex-row  flex-col lg:space-x-10   bg-[#EFF3FF] lg:px-12 sm:px-6  px-3 lg:py-9 p-6 rounded-2xl">
            <div className="flex-col lg:text-left text-center lg:w-[70%] mx-auto">
              <h4 className="lg:font-semibold font-medium">Unlock the Future of Customer Service with Inaipi DCC - <span className="text-[#006fff] ml-2">Start Your 1-Month Free Trial!</span> </h4>
              <p className="w-full  lg:flex hidden  mt-3 text-[#797979] lg:text-left text-center">
              Transform your customer interactions with our cutting-edge platform.<br/> Sign up today to experience:
              </p>
              {/* <img
                className="contactImg md:flex mx-auto hidden smmx-auto mt-8 w-[65%]"
                src={ImgContact}
                alt="contact_us"
              /> */}
              <div className="lg:flex items-center gap-[10%] mt-3 hidden">
              <List data={sign_up_features.slice(0, 6)}/>
              <List data={sign_up_features.slice(6, 12)}/>
              </div>
              <div className="elevate-text mt-6 lg:block hidden">Elevate your <span className="text-[#006fff] font-semibold"> Customer Experience</span> and see the difference today!</div>
            </div>
            <form
              ref={formRef}
              onSubmit={handleSubmit(formSubmit)}
              className="form lg:mt-0 mt-4 lg:w-[60%]"
            >
              <div className="flex flex-col space-y-2">
                <div className="flex items-center gap-4">
                  <div className="w-1/2 relative">
                    <label className="label">First name*</label>
                    <div className="w-full"><input
                      placeholder="Enter your first name"
                      className={!errors?.first_name ? "form-field" : "error-field"}
                      {...register("first_name")}
                      style={{width:"100%"}}
                    /></div>
                    <span className="text-[10px] text-red-500 whitespace-nowrap absolute -bottom-4">
                      {errors?.first_name?.message}{" "}
                    </span>
                  </div>
                  <div className="w-1/2 relative">
                    <label className="label">Last name*</label>
                    <div className="w-full"><input
                      placeholder="Enter your last name"
                      className={!errors?.last_name ? "form-field" : "error-field"}
                      {...register("last_name")}
                      style={{width:"100%"}}
                    /></div>
                    <span className="absolute -bottom-4 text-[10px] text-red-500 whitespace-nowrap">
                      {errors?.last_name?.message}  
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col space-y-2 relative">
                <label className="label">Company*</label>
                <input   {...register("company")} placeholder="company name" className={!errors?.company ? "form-field" : "error-field"} />
                <span className="text-[10px] text-red-500 whitespace-nowrap absolute -bottom-4">
                      {errors?.company?.message} 
                    </span>
              </div>
              <div className="flex flex-col space-y-2 relative">
                <label className="label">Email*</label>
                <input
                  {...register("email")}
                  placeholder="example@gmail.com"
                  className={!errors?.email ? "form-field" : "error-field"}
                />
                <span className="text-[10px] text-red-500 whitespace-nowrap absolute -bottom-4">
                  {errors?.email?.message}{" "}
                </span>
              </div>
              <div className="flex flex-col space-y-2 relative">
                <label className="label">Phone number*</label>
                <div className="flex space-x-3">
                <Controller
                    control={control}
                    name="country_code"
                    render={({ field}) => (

                    <PhoneInput
                    inputProps={
                      {
                        // disabled: true,
                      }
                    }
                    onChange={(data)=>field?.onChange((data))}  
                     className="form-field"
                    style={{ width: "30%" }}
                    country={"in"} 
                  />
                    )}
                />
                  
                  <input
                    {...register("phone_no")}
                    type="text"
                    className={!errors?.phone_no ? "form-field" : "error-field"}
                    style={{width:"100%", position:"relative"}}
                   />
                     
                </div>
                <span className="text-[10px] text-red-500 whitespace-nowrap absolute -bottom-4">
                  {errors?.phone_no?.message} 
                </span>
              </div>
             

              <button
                disabled={loading}
                className="sub-btn py-[18px] mt-3 relative"
                type="submit"
              >
                Submit
                <div className="absolute top-1/2  -translate-y-1/2 left-[48%]">
                {loading && <div className="custom-loader"></div>}
                </div>
              </button>

              <div className="text-[10px] text-gray-500">
                By clicking the button above, you confirm your agreement to our
                Terms of Service and Privacy Policy.
              </div>

              
            </form>
            <p className="w-full flex lg:hidden  mt-6 text-[#797979] lg:text-left text-center">
              Transform your customer interactions with our cutting-edge platform.<br/> Sign up today to experience:
              </p>
              <div className="flex items-center gap-[10%] mt-3 lg:hidden">
              <List data={sign_up_features.slice(0, 6)}/>
              <List data={sign_up_features.slice(6, 12)}/>
              </div>
            <div className="elevate-text mt-6 block lg:hidden w-full" style={{textAlign:"center"}}>Elevate your <span className="text-[#006fff] font-semibold"> Customer Experience</span> and see the difference today!</div>

          </div> 
      </div>
      <Footer />
    {/* {successInitialValue?.status &&  <SuccessOrFailToast successInitialValue = {successInitialValue} errorInitialValue resetSuccess={()=>resetSuccess()} resetError/>} */}
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
        className="custom-toast"
      />
    </div>
  );
}

export default SignUp;
