import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import Topbar from "../bars/TopNav";
import Footer from "../bars/Footer";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CustomTab from "../components/CustomTab";
import { customStyles } from "../contsants/constant";
import { isValidDateFormat } from "../utils/util";
function Datatable() {
  const [data, setData] = useState([]);
  const [docData, setDocData] = useState([]);
  const [search, setSearch] = useState("");
  const [docSearch, setDocSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [docFilteredData, setDocFilteredData] = useState([]);
  
 
  const getData = async () => {
    try {
      const response = await axios.get(
        "https://1vdi4lgmfa.execute-api.us-east-1.amazonaws.com/get_every_details"
      );
      const normalSortedData = response.data
        .sort((a, b) => b.updatedAt - a.updatedAt)
        .filter((item) => item?.lastname !== "doc_type" && !isValidDateFormat(item?.lastname));
      const docSortedData = response.data
        .sort((a, b) => b.updatedAt - a.updatedAt)
        .filter((item) => item?.lastname === "doc_type"); // Sorting the data based on updatedAt
      // Sorting the data based on updatedAt
      setData(normalSortedData);
      setFilteredData(normalSortedData);
      setDocFilteredData(docSortedData);
      setDocData(docSortedData);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      name: "First Name",
      selector: (row) => row.firstname,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastname,
    },
    {
      name: "Company",
      selector: (row) => row.company,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Comments",
      selector: (row) => row.comments,
    },
    {
      name: "Date",
      selector: (row) => convertDate(row.updatedAt),
    },
  ];

  const docColumns = [
    {
      name: "Name",
      selector: (row) => row.firstname,
    },

    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Doc Name",
      selector: (row) => row.company,
    },
    {
      name: "Date",
      selector: (row) => convertDate(row.updatedAt),
    },
  ];

  const convertDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    // Extract the day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // getMonth returns 0-11, so we add 1 to get the actual month
    const year = date.getFullYear();

    // Formatting the date to the desired format
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const extractDataForExport = () => {
    return filteredData.map((row) => ({
      "First Name": row.firstname,
      "Last Name": row.lastname,
      Company: row.company,
      Email: row.email,
      Phone: row.phone,
      Comments: row.comments,
    }));
  };

  const extractDocDataForExport = () => {
    return docFilteredData.map((row) => ({
      "Name": row.firstname,
      Document: row.company,
      Email: row.email,
      Comments: row.company,
    }));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const result = data.filter((row) => {
      return Object.values(row).some((value) =>
        value.toLowerCase().includes(search.toLowerCase())
      );
    });
    setFilteredData(result);

    const docResult = docData.filter((row) => {
      return Object.values(row).some((value) =>
        value.toLowerCase().includes(docSearch.toLowerCase())
      );
    });
    setDocFilteredData(docResult);
  }, [search, docSearch]);

  const NormalData = ({ onChange }) => {
    return (
      <div className="flex flex-col items-center mt-[10px] mb-20 relative">
        <DataTable
        title="Contact us List"
          customStyles={customStyles}
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="450px"
          selectableRowsHighlight
           
        />
         <div className="absolute z-10  top-0 right-0 flex space-x-2">
              <CSVLink
                data={extractDataForExport()}
                filename="mydata.csv"
                className="flex space-x-1 items-center p-3 text-sm bg-[#006fff] text-white rounded-md px-4"
              >
                <div>Export</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  class="w-5 h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                    clip-rule="evenodd"
                  />
                </svg>
              </CSVLink>
              <input
                type="text"
                autoFocus
                placeholder="Search here"
                className="p-2 border border-1-gray-300 rounded-md shadow-sm outline-none"
                value={search}
                onChange={(e) => onChange(e.target.value)}
              />
            </div>
      </div>
    );
  };

  const DocListData = ({ onChange }) => {
    return (
      <div className="flex flex-col items-center mt-[10px] mb-20 relative">
        <DataTable
        title="Document Downloaded List"
          customStyles={customStyles}
          columns={docColumns}
          data={docFilteredData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="450px"
       
           
        />
         <div className="absolute z-10  top-0 right-0 flex space-x-2">
              <CSVLink
                data={extractDocDataForExport()}
                filename="mydata.csv"
                className="flex space-x-1 items-center p-3 text-sm bg-[#006fff] text-white rounded-md px-4"
              >
                <div>Export</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  class="w-5 h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                    clip-rule="evenodd"
                  />
                </svg>
              </CSVLink>
              <input
                type="text"
                autoFocus
                placeholder="Search here"
                className="p-2 border border-1-gray-300 rounded-md shadow-sm outline-none"
                value={docSearch}
                onChange={(e) => onChange(e.target.value)}
              />
            </div>
      </div>
    );
  };

  var tabOptions = [
    {
      label: "Contact us List",
      value: "1",
      container: <NormalData onChange={(data) => setSearch(data)} />,
    },
    {
      label: "Document Downloaded List",
      value: "2",
      container: <DocListData onChange={(data) => setDocSearch(data)}/>,
    },
  ];

  return (
    <div cl>
      <Topbar />
      <div className="mt-[72px] top_section min-h-[650px]">
        <CustomTab tabOptions={tabOptions} />
      </div>
      <Footer />
    </div>
  );
}

export default Datatable;
