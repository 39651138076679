import React from "react";
import Footer from "../../bars/Footer";
import TopNav from "../../bars/TopNav";
import BgCover from "./images/blogsBg4.png";
import "./css/blog.css"
import BlogCard from "./components/BlogCard";
import { blog_cards } from "../../contsants/constant";
function Blog() {
  return (
    <div>
      <TopNav />
      <div className="blogs w-full h-full pb-10">
        <div
          className="blog-head h-[240px] lg:mt-[74px] mt-[68px] w-full flex items-center relative"
          style={{
            background: `url(${BgCover})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat", 
          }}
        >
            
            <div className="absolute left-0 top-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex top_section items-center h-full w-full relative">
            <h1 className="text-white">BLOGS</h1>
          </div>
        </div>
        <div className="mt-10 top_section">
            <div className="grid grid-flow-row md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-[2%] mt-7">
             {blog_cards.map((item, i)=>{
                return(
                    <BlogCard data={item}/> 
                )
             })}
            </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
