import React, {useEffect} from 'react'
import CareerA from '../career/CareerA.js'
import CareerB from '../career/CareerB.js'
import CareerC from '../career/CareerC.js'
import CareerD from '../career/CareerD.js'
import Topbar from '../bars/TopNav.js'
import Footer from '../bars/Footer.js'


function Career() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className='mt-[120px] career-page'>
            <Topbar />
            <CareerA />
            <CareerB />
            <CareerC />
            <CareerD />
            <Footer />
        </div>
    )
}

export default Career
