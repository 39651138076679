import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";

export default function AccordionUsage(props) {
  const { mainHead, subLinks } = props;
  return (
    <div>
      <Accordion>
        <AccordionSummary
          className="bg-red-300"
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="flex w-full justify-between">
            <span>{mainHead}</span> <span>↓</span>
          </div>
        </AccordionSummary>
        {subLinks?.map((item, i) => (
          <AccordionDetails>
            {item?.type === "aTag" ? (
              <a href={item?.link} target="_blank">
                {item?.name}
              </a>
            ) : (
              <Link to={item?.link}>{item?.name}</Link>
            )}
          </AccordionDetails>
        ))}
      </Accordion>
    </div>
  );
}
