import React from "react";
import ArrowIcon from "../../../svg/arrow.svg"
import { useNavigate } from "react-router-dom";

function BackButton() {
    const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(-1)}
      className="absolute top-6 arrow-btn w-8 h-8 rounded-full bg-white flex items-center justify-center"
    >
      <img src={ArrowIcon} alt="" />
    </button>
  );
}

export default BackButton;
